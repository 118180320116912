import React from "react"
import Reveal from 'react-reveal/Reveal'
import { graphql } from 'gatsby'

import PaddedContent from "components/paddedContent"
import { useBreakpoint } from "hooks/use-breakpoint"
import styles from "styles/sections/text-hero-section.module.scss"
import styleVars from "styles/variables.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const TextHeroSection = ({
  buttonLink,
  buttonText,
  heroImageMobile,
  heroImageDesktop,
  openInNewTab,
  markdown,
}) => {
  const [isTablet, loaded] = useBreakpoint(styleVars.breakpointTablet)

  return (
    <section className={styles.wrapper}>
      <div className={cx('background')} style={{ backgroundImage: loaded && `url(${isTablet ? heroImageMobile?.file?.url : heroImageDesktop?.file?.url})` }} />
      <PaddedContent>
        <Reveal effect="fadeInUp">
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: markdown?.childMarkdownRemark?.html,
              }}
              className={cx('heroDescription')}
            />
            {buttonLink && buttonText && (
              <a href={buttonLink} className={styles.cta} target={openInNewTab ? '_blank' : '_self'} rel="norefferer">
                {buttonText}
              </a>
            )}
          </>
        </Reveal>
      </PaddedContent>
    </section>
  )
}

export default TextHeroSection

export const textHeroSection = graphql`
  fragment TextHeroSection on ContentfulTextHeroSection {
    id
    buttonText
    buttonLink
    openInNewTab
    heroImageDesktop {
      file {
        url
      }
    }
    heroImageMobile {
      file {
        url
      }
    }
    markdown {
      childMarkdownRemark {
        html
      }
    }
    name
  }
`

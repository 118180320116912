import React, { useState } from "react"
import { Dialog } from "@reach/dialog"
import styleVars from "styles/variables.scss"
import "styles/animations.scss"
import styles from "styles/sections/cta-section.module.scss"
import classNames from "classnames/bind"
import FormBlock from "components/formBlock"
import CloseIcon from "images/icons/close.svg"
import "styles/components/hubspotForm.scss"
const cx = classNames.bind(styles)

const HubspotForm = ({ formId, formTarget, portalId, subtext, title, text, customerStyle }) => {
  const [openPopup, setOpenPopup] = useState(false)

  const openHubspotForm = () => {
    setOpenPopup(true)
  }

  const closeHubspotForm = () => {
    setOpenPopup(false)
  }

  const onSubmit = () => {
    setTimeout(() => {
      setOpenPopup(false)
    }, 5000)
  }
  return <div>
    <button className={cx('cta',customerStyle)} onClick={openHubspotForm}>
      {text}
    </button>

    <Dialog
      isOpen={openPopup}
      style={{
        padding: 0,
        width: "92vw",
        maxWidth: "1200px",
        position: "relative",
        zIndex: styleVars.popupZ,
      }}
      aria-label="Required form to access article"
    >
      <div className="hubspot-form">
        <FormBlock
          {...{ formId, formTarget, portalId, subtext, title, showLoadding: true }}
          onSubmit={onSubmit}
        />
      </div>
      <button className="hubspot-close-btn"  onClick={closeHubspotForm}>
        <CloseIcon />
      </button>
    </Dialog>
  </div>
}

export default HubspotForm

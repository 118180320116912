import React from "react"
import Reveal from "react-reveal/Reveal"
import "styles/animations.scss"

import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"
import WavyBackground from "components/wavyBackground"

import styles from "styles/sections/grid/simple-cards-section.module.scss"

const SimpleCardsSection = ({ sectionHeading, gridItems, sectionId, backgroundType }) => {
  return (
    <section id={sectionId}>
      {/* EC-12457: Add a "simple card" style to the "Grid Section" content model */}
      <WavyBackground isHideWave={backgroundType == "no waves" ? true : false}>
        <div className={styles.content}>
          {/* Want to include wavy background even if there aren't any cards*/}
          {sectionHeading && (
            <SectionHeader centered white {...sectionHeading} />
          )}
          {gridItems?.length && (
            <PaddedContent>
              <div className={styles.flex}>
                {gridItems.map((card, idx) => (
                  <div key={idx} className={styles.card}>
                    <Reveal effect="fadeIn">
                      <h2>{card.title}</h2>
                    </Reveal>
                    {card.subtitle && (
                      <Reveal effect="fadeIn">
                        <h6>{card.subtitle}</h6>
                      </Reveal>
                    )}
                    <Reveal effect="fadeUp" duration={200}>
                      <p>{card.text}</p>
                    </Reveal>
                  </div>
                ))}
              </div>
            </PaddedContent>
          )}
        </div>
      </WavyBackground>
    </section>
  )
}

export default SimpleCardsSection

import React from "react"

import styles from "styles/components/carousel-arrows.module.scss"

const CarouselArrows = ({ next, prev }) => {
  return <div className={styles.container}>
    <button
      className={styles.arrowContainer}
      aria-label={`Prev Carousel Item`}
      onClick={() => prev()}
    >
      {"<"}
    </button>
    <button
      className={styles.arrowContainer}
      aria-label={`Next Carousel Item`}
      onClick={() => next()}
    >
      {">"}
    </button>
  </div>
}

export default CarouselArrows

import React from "react"
import { graphql } from "gatsby"
import { HERO_TYPES } from "constants.js"
import VideoHero from "./videoHero"
import TextAndImageHero from "./textAndImageHero"
import ImageHero from "./imageHero"
import TextCenterHero from "./textCenterHero"
import MapHero from "./mapHero"

const HeroSection = (props) => {
  switch (props.type) {
    case HERO_TYPES.video:
      return <VideoHero {...props} />
    case HERO_TYPES.textAndImage:
      return <TextAndImageHero {...props} />
    case HERO_TYPES.image:
      return <ImageHero {...props} />
    case HERO_TYPES.textCenter:
      return <TextCenterHero {...props} />
    case HERO_TYPES.map:
      return <MapHero {...props} />
    default:
      return null
  }
}

export default HeroSection

export const heroSection = graphql`
  fragment HeroSection on ContentfulHeroSection {
    id
    type
    label
    header
    subheader
    buttonLink
    buttonText
    backgroundType
    openInNewTab
    disableSocialShare
    shortBanner
    video {
      ...Video
    }
    heroImageDesktop {
      title
      file {
        url
      }
      fluid(quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    heroImageMobile {
      title
      file {
        url
      }
      fluid(quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    mapText {
      childMarkdownRemark {
        html
      }
    }
    mapLocation {
      lat
      lon
    }
  }
`

import React from "react"
import { Link } from "gatsby"
import { useBreakpoint } from "hooks/use-breakpoint"

import PaddedContent from "components/paddedContent"
import PageHeader from "components/pageHeader"
import Video from "components/video"

import styles from "styles/sections/hero/video-hero.module.scss"
import styleVars from "styles/variables.scss"
import BackgroundHero from "components/backgroundHero"

const VideoHero = ({
  label,
  header,
  subheader,
  buttonText,
  buttonLink,
  backgroundType,
  video,
}) => {
  const [isTablet] = useBreakpoint(styleVars.breakpointTablet)
  // Background Type
  if(backgroundType=="manatee"){
    backgroundType = "light mint"
  }
  const backgroundNavy = backgroundType === "navy" || !backgroundType

  return (
    <section className={styles.wrapper}>
    <div className={styles.background}>
      <BackgroundHero backgroundType={backgroundType} isTablet={isTablet} />
    </div>
      <PaddedContent>
        <div className={styles.content}>
          <div className={styles.textContainer}>
            <PageHeader
              white={backgroundNavy}
              maxWidthTitle="460px"
              maxWidthSubtext="460px"
              label={label}
              title={header}
              titleClass="h2"
              subtext={subheader}
            />
            {buttonLink && buttonText && (
              <Link to={buttonLink} className={`${styles.cta} ${backgroundNavy ? styles.navyBg : styles.lightBg}`}>
                {buttonText}
              </Link>
            )}
          </div>
          <div className={styles.videoContainer}>
            {video && (
              <Video className={styles.video} {...video} />
            )}
          </div>
        </div>
      </PaddedContent>
    </section>
  )
}

export default VideoHero

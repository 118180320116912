import React, { useEffect } from 'react'
import styles from "styles/components/dialog-bio.module.scss"
import Reveal from "react-reveal/Reveal"
import RichText from "components/richText"


export default function DialogBio({ show, close, selectedBio }) {
  const { name, role, text, avatar } = selectedBio || {}
  const dialogName = `inner-dialog-bio-${name}`
  
  useEffect(() => {
    function checkIsClickOutsideDialog(event) {
      const path = []
      if (!path.length) {
        let currentElem = event.target;
        while (currentElem) {
          path.push(currentElem);
          currentElem = currentElem.parentElement;
        }
        if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
          path.push(document);
        if (path.indexOf(window) === -1)
          path.push(window);
      }

      for (const el of path) {
        if (typeof el.getAttribute === 'function' && el.getAttribute('dialog-name') === dialogName) {
          return false
        }
      }
      return true
    }
    function onDocumentMouseDown(event) {
      if (checkIsClickOutsideDialog(event)) {
        close()
      }
    }
    if (show) {
      document.addEventListener("mousedown", onDocumentMouseDown);
    } else {
      document.removeEventListener("mousedown", onDocumentMouseDown);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", onDocumentMouseDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <div>
      {show && (
        <div className={styles.wrapDialogBio}>
          <Reveal effect="fadeUp" duration={200}>
            <div className={styles.dialogInner} dialog-name={dialogName}>
              <button className={styles.btnClosePopup} onClick={() => close()}>&#x2715;</button>
              <div>
                <div className={styles.dialogHeader}>
                  <div
                    className={styles.avatar}
                    style={{ backgroundImage: `url(${avatar?.file?.url})` }}
                  />
                  <div className={styles.bioInfo}>
                    <h3>{name}</h3>
                    <span>{role}</span>
                  </div>
                </div>
                {text && (
                  <RichText text={text} />
                )}
              </div>
            </div>
          </Reveal>
        </div>
      )}
    </div>
  )
}

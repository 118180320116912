import React from "react"
import { useBreakpoint } from "hooks/use-breakpoint"

import styles from "styles/components/teal-wave-background.module.scss"
import styleVars from "styles/variables.scss"

import tealWaveBackgroundImgMobile from "images/teal-wave-bg-mobile.png"
import tealWaveBackgroundImg from "images/teal-wave-bg.png"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const TealWaveBackground = ({ children }) => {
  const [isTablet, loaded] = useBreakpoint(styleVars.breakpointTablet)

  return (
    <div className={cx('container')}>
      {/* <div className={cx('tealWaveBackground')}  /> */}
      <div className={cx('tealWaveContainer')} style={{ backgroundImage: loaded && `url(${isTablet ? tealWaveBackgroundImgMobile : tealWaveBackgroundImg})` }}>{children}</div>
    </div>
  )
}

export default TealWaveBackground
import React from "react"
import { Link } from "gatsby"
import { useBreakpoint } from "hooks/use-breakpoint"
import Img from "gatsby-image"

import PaddedContent from "components/paddedContent"
import PageHeader from "components/pageHeader"


import styles from "styles/sections/hero/text-and-image-hero.module.scss"
import styleVars from "styles/variables.scss"
import BackgroundHero from "components/backgroundHero"
import { isURL } from "variables.js"
const VideoHero = ({
  label,
  header,
  subheader,
  buttonText,
  buttonLink,
  backgroundType,
  heroImageDesktop,
  heroImageMobile,
}) => {
  const [isTablet, loaded] = useBreakpoint(styleVars.breakpointTablet)
  const backgroundNavy = backgroundType === "navy" || !backgroundType
  let isInnternalLink = true
  if (isURL(buttonLink)) {
    isInnternalLink = false
  }

  return (
    <section className={styles.wrapper}>
    <div className={styles.background}>
      <BackgroundHero backgroundType={backgroundType} isTablet={isTablet} />
    </div>
      <PaddedContent>
        <div className={styles.content}>
          <div className={styles.textContainer}>
            <PageHeader
              white={backgroundNavy}
              maxWidthTitle="460px"
              maxWidthSubtext="460px"
              label={label}
              title={header}
              titleClass="h2"
              subtext={subheader}
            />
            {buttonLink && buttonText && (
              isInnternalLink?(<Link to={buttonLink} className={styles.cta}>
                {buttonText}
              </Link>):(<a href={buttonLink} className={styles.cta} target='_blank' rel="norefferer">
                {buttonText}
              </a>)
            )}
          </div>
          <div className={styles.imageContainer}>
            {loaded && (
              <>
                {isTablet && heroImageMobile?.fluid && (
                  <Img
                    className={styles.image}
                    fluid={heroImageMobile.fluid}
                    alt={heroImageDesktop.title}
                  />
                )}
                {!isTablet && heroImageDesktop?.fluid && (
                  <Img
                    className={styles.image}
                    fluid={heroImageDesktop.fluid}
                    alt={heroImageDesktop.title}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </PaddedContent>
    </section>
  )
}

export default VideoHero

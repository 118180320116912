import React from "react"
import Reveal from "react-reveal/Reveal"
import "styles/animations.scss"
import { TITLE_COLOR } from "constants.js"

import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"

import styles from "styles/sections/grid/one-col-text-section.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const LongTextBlock = ({ title, longText, icon, titleColor }) => {
  return (
    <div className={cx('textBlock', !icon?.file?.url && 'textBlockWithoutIcon')}>
      {icon?.file?.url && (
        <div className={styles.icon}>
          <Reveal effect="fadeIn">
            <img src={icon.file.url} alt={title} />
          </Reveal>
        </div>
      )}
      <div className={styles.content}>
        <Reveal effect="fadeIn">
          <h4
            className={cx(styles.title, TITLE_COLOR[titleColor])}
          >
            {title}
          </h4>
        </Reveal>
        {longText?.childMarkdownRemark?.html && (
          <Reveal effect="fadeUp" duration={200}>
            <div
              dangerouslySetInnerHTML={{
                __html: longText.childMarkdownRemark.html,
              }}
            />
          </Reveal>
        )}
      </div>
    </div>
  )
}

const OneColTextSection = ({
  sectionHeading,
  gridItems,
  sectionId,
  backgroundType,
}) => {

  return (
    <section
      className={cx("container", {
        white: backgroundType === "white",
        lightMint: backgroundType === "light mint",
        mint: backgroundType === "mint",
      })}
      id={sectionId}
    >
      <PaddedContent>
        {sectionHeading && (
          <div className={styles.header}>
            <SectionHeader
              {...sectionHeading}
              maxWidthSubtext="unset"
              maxWidthTitle="unset"
              centered
            />
          </div>
        )}
        {gridItems && (
          <div className={styles.longTextBlockGrid}>
            {gridItems.map((textBlock, i) => {
              return <LongTextBlock {...textBlock} key={i} />
            })}
          </div>
        )}
      </PaddedContent>
    </section>
  )
}

export default OneColTextSection

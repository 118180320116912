import React from 'react'
import { graphql } from 'gatsby'
import Video from 'components/video'

import styles from 'styles/sections/video-section.module.scss'
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const VideoSection = (data) => {
  return (
    <section className={cx('wrapper')}>
      <Video {...data} />
    </section>
  )
}

export default VideoSection

export const videoSection = graphql`
  fragment VideoSection on ContentfulVideo {
    ...Video
  }
`

import React from 'react'
import Reveal from 'react-reveal/Reveal'

import PaddedContent from 'components/paddedContent'

import styles from "styles/sections/grid/image-grid-section.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const imageGridSection = ({ sectionHeading, gridItems }) => {
  return (
    <PaddedContent>
      <Reveal effect="fadeInUp">
        <h2 className={cx('textCenter')}>{sectionHeading?.title}</h2>
      </Reveal>

      <Reveal effect="fadeInUp">
        <div className={cx('wrapGridItems')}>
          {gridItems && gridItems.map((gridItem, index) => (
            // <Img fluid={gridItem?.image?.fluid} className={cx('gridItem')} width="100%" key={`image-grid-item-${index}`} alt={gridItem.name} />
            <img src={gridItem?.image?.file?.url} className={cx('gridItem')} width="100%" key={`image-grid-item-${index}`} alt={gridItem.name} />
          ))}
        </div>
      </Reveal>
    </PaddedContent>
  )
}

export default imageGridSection
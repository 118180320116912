import React from "react"
import { graphql } from 'gatsby'

import styles from "styles/sections/spacer.module.scss"
import classNames from "classnames/bind"
import { useBreakpoint } from "hooks/use-breakpoint"
import styleVars from "styles/variables.scss"
const cx = classNames.bind(styles)

const MAP_BACKGROUND = {
  "mint": 'mint',
  "navy": 'navy',
  "wavy": 'navy',
  "teal": 'teal',
  "light mint": 'lightMint',
  "white": 'white',
  "none": 'none',
}

const Spacer = ({ sectionSpaceOnMobile, sectionSpaceOnTablet, sectionSpaceOnDesktop, backgroundType }) => {
  const [isMobile] = useBreakpoint(styleVars.breakpointMobile)
  const [isTablet] = useBreakpoint(styleVars.breakpointTablet)
  const height = (isMobile ? sectionSpaceOnMobile : (isTablet ? sectionSpaceOnTablet : sectionSpaceOnDesktop)) || 0
  if (!height) return false
  return (
    <div className={cx('spaceModule', MAP_BACKGROUND[backgroundType])} style={{
      height
    }}/>
  )
}

export default Spacer

export const textSection = graphql`
  fragment Spacer on ContentfulSpacer {
    sectionSpaceOnMobile
    sectionSpaceOnTablet
    sectionSpaceOnDesktop
    backgroundType
  }
`

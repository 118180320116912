import React from "react"
import Reveal from "react-reveal/Reveal"
import "styles/animations.scss"

import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"
import Card from "components/card"
import styles from "styles/sections/grid/card-section.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const CardSection = ({
  sectionHeading,
  gridItems,
  sectionId,
  backgroundType,
}) => {
  return (
    <section
      className={cx("container", {
        white: backgroundType === "white",
        lightMint: backgroundType === "light mint",
        mint: backgroundType === "mint",
      })}
      id={sectionId}
    >
      <PaddedContent>
        <Reveal effect="fadeUp" duration={700}>
          <>
            {sectionHeading && (
              <SectionHeader centered={true} {...sectionHeading} />
            )}
            {gridItems && (
              <div className={styles.cardContainer}>
                {gridItems.map((card, i) => (
                  <Card horizontal {...card} withoutAnimation={true} key={i} />
                ))}
              </div>
            )}
          </>
        </Reveal>
      </PaddedContent>
    </section>
  )
}

export default CardSection

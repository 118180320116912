import React, { useState, useRef, useEffect } from "react"
import Reveal from 'react-reveal/Reveal'
import { graphql } from 'gatsby'

import styles from "styles/sections/tab-section.module.scss"
import classNames from "classnames/bind"

import PaddedContent from "components/paddedContent"
import ContentfulLink from "components/contentfulLink"

import IconCheck from "images/icons/icon-check.svg"
import LinkTextWithChev from "components/linkTextWithChev"
import { isURL } from "variables.js"
const cx = classNames.bind(styles)

const mapColor = {
  'Teal': '#00b2a9',
  'Red': '#8d99ae',
  'Navy': '#041e42',
  'Manatee': '#8d99ae'
}

const TabSection = ({
  header,
  tabItems = []
}) => {
  const [currentTab, setCurrentTab] = useState(0)
  const divAnim = useRef(null)
  const tabRefs = useRef([])
  const handleSetTab = value => {
    setCurrentTab(value)
    const tabActive = tabRefs.current[value]
    const { width = 0 } = tabActive?.getBoundingClientRect() || {}
    divAnim.current.style.width = width + 'px'
    divAnim.current.style.left = (tabActive?.offsetLeft || 0) + 'px'
    divAnim.current.style.backgroundColor = mapColor[tabItems[value]?.colors]
  }


  useEffect(() => {
    handleSetTab(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleResize = () => {
      handleSetTab(currentTab)
    }
    window.removeEventListener("resize", handleResize)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  return (
    <section className={cx('tabSection')}>
      <PaddedContent>
        <div className={cx('container')}>
          <Reveal effect="fadeInUp" duration={1000}>
            <div className={cx('wrapHeading')}>
              <div className={cx("flex", "justifyCenter", 'wrapCircles')}>
                <div className={cx("circle", "circleTeal")}></div>
                <div className={cx("circle", "circleRed")}></div>
                <div className={cx("circle", "circleNavy")}></div>
              </div>
              <h2>{header}</h2>
            </div>

            <div className={cx('relative')}>
              <div className={cx('tabs')}>
                {tabItems.map((tabItem, tIndex) => (
                  <button
                    aria-label={`Button change to tab ${tabItem.tabTitle}`}
                    ref={el => tabRefs.current[tIndex] = el}
                    key={`section-tab-title-${tIndex}`}
                    className={cx('tabItem', tabItem.colors, currentTab === tIndex ? 'active' : '')}
                    onClick={() => handleSetTab(tIndex)}
                    onKeyDown={() => handleSetTab(tIndex)}
                  >
                    {tabItem.tabTitle}
                  </button>
                ))}
              </div>
              <div ref={divAnim} className={cx('tabActiveColor')} />
            </div>
          </Reveal >
          <Reveal effect="fadeInUp" duration={1000}>

            <div className={cx('wrapTabContent')}>
              {tabItems.map((tabItem, tIndex) => {
                const ctaLink = {
                  linkText: tabItem.buttonText
                }
                if (isURL(tabItem.buttonLink)) {
                  ctaLink.externalLink = tabItem.buttonLink
                } else {
                  ctaLink.internalLink = tabItem.buttonLink
                }
                return (currentTab === tIndex && (
                  <Reveal duration={500}>
                    <div key={`section-tab-content-${tIndex}`} className={cx('tabContent')}>
                      <div>
                        <div className={cx('tabItemImage')} >
                          <div className={cx('backgroundColor', tabItem.colors, 'bgActive')}></div>
                          <div className={cx('tabItemImageInner')} style={{ backgroundImage: `url(${tabItem?.image?.file?.url})` }}></div>
                        </div>
                      </div>

                      <div>
                        <div className={cx('wrapChecklist')}>
                          {(tabItem.listItems || []).map((listItem, lIndex) => (
                            <div key={`section-tab-content-${tIndex}-list-item-${lIndex}`} className={cx('flex')}>
                              <IconCheck className={cx('iconCheck')} />
                              <div className={cx('content')}>
                                <h3>{listItem.title}</h3>
                                <p>{listItem.text}</p>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className={cx("flex", 'wrapLink', 'itemsCenter')}>
                          <ContentfulLink {...ctaLink} className={cx('link')}>
                            <LinkTextWithChev text={ctaLink.linkText} />
                          </ContentfulLink>
                        </div>
                      </div>
                    </div>
                  </Reveal>
                ))
              })}
            </div>
          </Reveal>
        </div>
      </PaddedContent>
    </section>
  )
}

export default TabSection

export const tabSection = graphql`
  fragment TabSection on ContentfulTabSection {
    __typename
    header
    tabItems {
      tabTitle
      colors
      buttonText
      buttonLink
      image {
        file {
          url
        }
      }
      listItems {
        title
        text
      }
    }
  }
`

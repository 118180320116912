import React, { useState, useEffect, useRef } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { useBreakpoint } from "hooks/use-breakpoint"
import { mod } from "variables.js"

import styles from "styles/sections/carousel/quote-carousel.module.scss"
import styleVars from "styles/variables.scss"

import QuoteCard from "components/quoteCard"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 2,
  },
  laptop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const CustomDot = ({ onClick, active, index, carouselState, selectActive }) => {
  return (
    <li
      role="presentation"
      className={cx("dot", { active: active })}
      onClick={() => {
        selectActive(index)
        onClick()
      }}
    />
  )
}

const QuoteCarousel = ({ delay, carouselItems, sectionId, backgroundType }) => {
  const [isTablet] = useBreakpoint(styleVars.breakpointTablet)

  const [active, setActive] = useState(0)
  const [autoPlay, setAutoPlay] = useState(true)
  const autoPlaySpeed = delay

  const selectActive = (i) => {
    setActive(i)
  }

  const timeout = useRef()
  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current)
    }
  }, [])

  return (
    <section
      className={cx("container", {
        white: backgroundType === "white",
        lightMint: backgroundType === "light mint",
      })}
      id={sectionId}
    >
      <Carousel
        responsive={responsive}
        centerMode={isTablet ? false : true}
        infinite
        containerClass="quote-carousel-container"
        itemClass="quote-carousel-item"
        arrows={false}
        showDots
        customDot={<CustomDot selectActive={selectActive} />}
        beforeChange={(nextSlide, ref) => {
          const { deviceType } = ref

          // make sure active slide is correct on different screen widths
          // not sure why this works but it does?
          if (deviceType === "laptop" || deviceType === "tablet") {
            setActive(mod(nextSlide - 2, carouselItems.length))
          } else {
            setActive(mod(nextSlide - 4, carouselItems.length))
          }
        }}
        afterChange={() => {
          // workaround to reset autoplay timer when user changes the active slide manually
          setAutoPlay(false)
          timeout.current = setTimeout(() => setAutoPlay(true), autoPlaySpeed)
        }}
        draggable={true}
        swipeable={true}
        keyBoardControl={true}
        minimumTouchDrag={10}
        autoPlay={autoPlay}
        autoPlaySpeed={autoPlaySpeed}
      >
        {carouselItems.map((quote, i) => {
          return <QuoteCard active={i === active} {...quote} key={i} />
        })}
      </Carousel>
    </section>
  )
}

export default QuoteCarousel

import React from 'react'
import { graphql } from 'gatsby'
import FeaturedArticlesCarousel from "components/featuredArticlesCarousel"

const FeaturedArticlesSection = ({ featuredArticles }) => {
  return (
    <FeaturedArticlesCarousel articles={featuredArticles} />
  )
}

export default FeaturedArticlesSection

export const featuredArticlesSection = graphql`
  fragment FeaturedArticlesSection on ContentfulFeaturedArticlesSection {
    id
    featuredArticles {
      title
      slug
      description
      category {
        text
      }
      heroImage {
        title
        fluid(maxHeight: 480, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

import React from "react"

import styles from "styles/components/carousel-indicators.module.scss"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const CarouselIndicators = ({ numItems, active, selectActive }) => {
  let indicators = []
  for (let i = 0; i < numItems; i++) {
    indicators.push(
      <button
        className={styles.dotContainer}
        aria-label={`Select Carousel Position ${i}`}
        onClick={() => selectActive(i)}
        key={i}
      >
        <div className={cx("dot", { active: i === active })} />
      </button>
    )
  }
  return <div className={styles.container}>{indicators}</div>
}

export default CarouselIndicators

import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { SwitchTransition, CSSTransition } from "react-transition-group"
import { Link } from "gatsby"
import {queryGreenhouseJob} from "hooks/use-greenhouseJob"
import { setFilter1, setFilter2 } from "../state/actions"
import styles from "styles/components/board-grid.module.scss"
import PaddedContent from "components/paddedContent"
import ArticleSelect from "components/articleSelect"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const FilteredBoardsGrid = ({
  filter1,
  filter2,
  setFilter1,
  setFilter2
}) => {
  // EC-8656: Incorrect job shown on job detail page
  const [data, setData] = useState(null);
  const [Jobs, setJobs] = useState([]);
  const [Offices, setOffices] = useState([]);
  const [Departments, setDepartments] = useState([]);
  const [SelectOption, setSelectOption] = useState([]);
  const departments = new Set()
  departments.add("All")
  const offices = new Set()
  const select = []
  let jobs = []
  select.push({ label: "All", value: "All" })
  useEffect(() => {
    queryGreenhouseJob().then(response => {
      setData(response);
      response.forEach((node) => {
        jobs.push(creatJob(node))
        departments.add(node.departments[0].name);
        node.offices.forEach((office) => {
          offices.add(office.name)
        })
      })
      setJobs(jobs);
      setDepartments(departments);
      setOffices(offices);
      for (const item of offices) {
        select.push({ label: item, value: item })
      }
      select.sort((a, b) => a.value.localeCompare(b.value))
      setSelectOption(select);
    }).catch(error => {
      console.error(error)
    });
  }, []);
  
  const changeFilter = (event) => {
    if (event.target && event.target.getAttribute("value")) {
      const department = event.target.getAttribute("value")
      setFilter1(department)
      updateJobList(department)
    }
  }

  const creatJob = (item)=>{
    const obj = { id: item.id, title: item.title, location: item.location.name, department: item.departments[0].name }
    return obj
  }

  const updateJobList = (department)=>{
    let updateJob = []
    if (department == "All") {
      if(!filter2.value||filter2.value=="All"){
        data.forEach((node) => {
          updateJob.push(creatJob(node))
        })
      } else {
        data.forEach((node) => {
          node.offices.forEach(office=>{
            if (office.name == filter2.value) {
              updateJob.push(creatJob(node))
            }
          })
        })
      }
    } else if(!filter2.value||filter2.value=="All"){
      data.forEach((node) => {
        if (node.departments[0].name == department) {
          updateJob.push(creatJob(node))
        }
      })
    } else{
      data.forEach((node) => {
        if (node.departments[0].name == department) {
          node.offices.forEach(office=>{
            if (office.name == filter2.value) {
              updateJob.push(creatJob(node))
            }
          })
        }
      })
    }
    setJobs(updateJob)
  }
  useEffect(() => {
    if(filter2.value){
      updateJobList(filter1?filter1:"All")
    }
  }, [filter2]);

  return (
    <div className={styles.container}>
      <PaddedContent>
        <div className={styles.filters}>
          <div className={styles.typeFilters}>
            {[...Departments].map((option, i) => (
              <span
                key={i}
                className={cx("typeFilter", {
                  active: !filter1 && option == "All" || filter1 == option,
                })}
                onClick={changeFilter}
                value={option}
              >
                {option}
              </span>
            ))}
          </div>
          {Offices.size > 0 && (
            <div className={styles.selectWrapper}>
              <ArticleSelect
                options={SelectOption}
                onChange={setFilter2}
                value={
                  filter2 ? (filter2 === null ? null : filter2) : null
                }
              />
            </div>
          )}
        </div>
      </PaddedContent>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`0`}
          classNames="article-fade"
          timeout={200}
        >
          <PaddedContent mobilePadding={8}>
            <div className={styles.container}>
              <div className={cx("gridContainer")}>
                {(
                  Jobs.length ? (
                    Jobs.map((item, i) => (
                      <div key={i} className={cx("articleWrapper", "team")}>
                        <Link to={`/jobs?job_id=${item.id}`}>
                          <div className={styles.innerWrapper}>
                            <div className={styles.teamMember}>
                              <div className={styles.text}>
                                <h4>{item.department}</h4>
                                <p>{item.title}</p>
                                <h6>{item.location}</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))):<div className={styles.empty}>0 jobs</div>
                )}
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </PaddedContent>
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}

const mapStateToProps = ({ filter1, filter2 }) => ({
  filter1,
  filter2,
})

const mapDispatchToProps = (dispatch) => ({
  setFilter1: (value) => dispatch(setFilter1(value)),
  setFilter2: (value) => dispatch(setFilter2(value))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilteredBoardsGrid)
import React, { useState, useEffect, useRef, useMemo } from "react"
import Img from "gatsby-image"
import { CSSTransition } from "react-transition-group"
import { mod } from "variables.js"

import PaddedContent from "components/paddedContent"
import VideoButton from "components/videoButton"
import CarouselArrows from "components/carouselArrows"

import styles from "styles/sections/carousel/carousel-background-image-item.module.scss"
import styleVars from "styles/variables.scss"

const CarouselItem = ({
  video,
  backgroundImageDesktop,
  backgroundImageMobile,
  startCarousel,
  stopCarousel,
}) => {
  const breakpointTablet = parseInt(styleVars.breakpointTablet)
  const backgroundSources = []
  if (backgroundImageMobile) {
    backgroundSources.push({
      ...(backgroundImageMobile.fluid || {}),
      media: `(max-width: ${breakpointTablet}px)`,
    })
  }
  if (backgroundImageDesktop) {
    backgroundSources.push({
      ...(backgroundImageDesktop.fluid || {}),
      media: `(min-width: ${breakpointTablet + 1}px)`,
    })
  }
  return (
    <div className={styles.carouselItem}>
      <Img
        fluid={backgroundSources}
        alt="Background image"
        className={styles.backgroundImage}
        style={{ position: "absolute", zIndex: "-1", top: "0" }}
      />
      <div className={styles.overlay} />
      <PaddedContent>
        {video && (
          <VideoButton
            {...video}
            onShow={stopCarousel}
            onHide={startCarousel}
          />
        )}
      </PaddedContent>
    </div>
  )
}

const CarouselBackgroundImageItem = ({ delay, carouselItems, sectionId, sectionHeading }) => {
  const [active, setActive] = useState(0)
  const interval = useRef()
  const intervalTime = delay
  const swipePos = useRef()
  const inSwipe = useRef()
  const localCarouselItems = useMemo(() => Array.isArray(carouselItems) ? carouselItems : [], [carouselItems])
  const isMultipleItems = localCarouselItems.length > 1
  
  const [cssTransitionClassNames, setCssTransitionClassNames] = useState('background-next')
  let timeout = null

  const startCarousel = () => {
    if (isMultipleItems) {
      interval.current = setInterval(() => incrementActive(), intervalTime)
    }
  }

  const stopCarousel = () => {
    if (isMultipleItems) {
      clearInterval(interval.current)
    }
  }

  const next = () => {
    stopCarousel()
    let nextActive = active + 1
    if (nextActive >= localCarouselItems.length) {
      nextActive = 0
    }
    setCssTransitionClassNames('background-next')
    setActive(nextActive)
    startCarousel()
  }
  const prev = () => {
    stopCarousel()
    let prevActive = active - 1
    if (prevActive <= -1) {
      prevActive = localCarouselItems.length - 1
    }
    setCssTransitionClassNames('background-prev')
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      setCssTransitionClassNames('background-next')
    }, 600);
    setActive(prevActive)
    startCarousel()
  }

  const incrementActive = () => {
    setActive((prevActive) => mod(prevActive + 1, localCarouselItems.length))
  }

  const decrementActive = () => {
    setActive((prevActive) => mod(prevActive - 1, localCarouselItems.length))
  }

  useEffect(() => {
    startCarousel()
    return () => {
      stopCarousel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTouchStart = (e) => {
    swipePos.current = e.nativeEvent.targetTouches[0].clientX
  }

  const handleTouchMove = (e) => {
    if (inSwipe.current) return
    var x = e.nativeEvent.targetTouches[0].clientX
    if (x > swipePos.current + 30) {
      stopCarousel()
      decrementActive()
      startCarousel()
      inSwipe.current = true
    } else if (x < swipePos.current - 30) {
      stopCarousel()
      incrementActive()
      startCarousel()
      inSwipe.current = true
    }
  }

  const handleTouchEnd = () => {
    inSwipe.current = false
  }
  // const prevActiveRef = useRef()
  // useEffect(() => {
  //   if (prevActiveRef.current <= active) {
  //     // next
  //     setCssTransitionClassNames('background-next')
  //   } else {
  //     // prev
  //     setCssTransitionClassNames('background-prev')
  //   }
  //   prevActiveRef.current = active
  // }, [active])

  return (
    <section
      className={styles.wrapper}
      id={sectionId}
      onTouchStart={isMultipleItems ? handleTouchStart : null}
      onTouchMove={isMultipleItems ? handleTouchMove : null}
      onTouchEnd={isMultipleItems ? handleTouchEnd : null}
    >
      {localCarouselItems.map((item, i) => (
        <CSSTransition
          in={i === active}
          timeout={500}
          mountOnEnter
          classNames={cssTransitionClassNames}
          key={i}
        >
          <CarouselItem
            {...item}
            startCarousel={startCarousel}
            stopCarousel={stopCarousel}
          />
        </CSSTransition>
      ))}
      <div className={styles.wrapContent}>
        <PaddedContent fullHeight={true}>
          <div className={styles.content}>
            <h1 className={styles.name}>{sectionHeading.title}</h1>
            {isMultipleItems && (
              <div className={styles.arrowsWrapper}>
                <CarouselArrows
                  numItems={localCarouselItems.length}
                  active={active}
                  next={() => next()}
                  prev={() => prev()}
                />
              </div>
            )}
          </div>
        </PaddedContent>
      </div>
    </section>
  )
}

export default CarouselBackgroundImageItem

import React, { useState } from "react"
import Img from "gatsby-image"
import { CSSTransition } from "react-transition-group"

import VideoPopup from "components/videoPopup"

import styles from "styles/components/quote-card.module.scss"
import DoubleQuotationMarksIcon from "images/icons/double-quotation-marks.svg"
import PlayButtonWhite from "images/icons/play-button-white.svg"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const QuoteCard = ({
  active,
  name,
  title,
  quote,
  childContentfulQuoteCardQuoteTextNode,
  color,
  headshot,
  video,
  videoThumbnail,
  cornerThumbnail,
  cornerThumbnailType,
}) => {
  const [showPopup, setShowPopup] = useState(false)
  const open = () => {
    setShowPopup(true)
  }
  const close = () => {
    setShowPopup(false)
  }

  const isLogo = cornerThumbnailType === "logo"
  return (
    <>
      <CSSTransition in={true} timeout={200} classNames="card">
        <div
          className={cx("container", color, {
            videoCard: videoThumbnail,
            active: active,
          })}
        >
          {videoThumbnail?.fluid && (
            <button
              className={styles.videoThumbnail}
              onClick={open}
              aria-label="Open Video"
            >
              <Img fluid={videoThumbnail.fluid} alt="" />
              <div className={styles.overlay} />
              <PlayButtonWhite />
            </button>
          )}
          {quote && (
            <p className={styles.quote}>{quote}</p>
            )}
          {childContentfulQuoteCardQuoteTextNode && (
            <div
              className={styles.quote}
              dangerouslySetInnerHTML={{
                __html: childContentfulQuoteCardQuoteTextNode?.childMarkdownRemark?.html,
              }}
            />
          )}
          <p className={styles.name}>{name}</p>
          <p className={styles.title}>{title}</p>
          {videoThumbnail && <DoubleQuotationMarksIcon className={styles.quoteMarks} />}
          {cornerThumbnail?.fluid && (
            <div className={cx("cornerThumbnail", { logo: isLogo })}>
              <Img
                fluid={cornerThumbnail.fluid}
                alt={name}
                style={isLogo ? { height: "58px", width: "58px" } : null}
                imgStyle={isLogo ? { objectFit: "contain" } : null}
              />
            </div>
          )}
        </div>
      </CSSTransition>
      {video && (
        <VideoPopup
          isOpen={showPopup}
          onDismiss={close}
          {...video}
        />
      )}
    </>
  )
}

export default QuoteCard

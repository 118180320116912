import React from "react"
import Reveal from "react-reveal/Reveal"

import ContentfulLink from "components/contentfulLink"

import "../styles/animations.scss"
import styles from "../styles/components/card.module.scss"
import RightCarrot from "images/icons/right-carrot.svg"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)
const MAP_COLOR_TYPE = {
  'Teal': 'red',
  'Navy': 'navy',
  'Light Gray': 'mint',
  'Dark Gray': 'grey'
}
const Card = (props) => {
  const { withoutAnimation } = props
  return (
    <div
      className={cx("card", MAP_COLOR_TYPE[props.color], {
        dummy: props.dummy,
        horizontal: props.horizontal,
        vertical: props.vertical,
        shadow: props.shadow,
        moreThanThree: props.moreThanThree, // enables 2 column layout
      })}
    >
      <div className={styles.flex}>
        <div>
          {props.icon?.file.url && (
            <>
              {withoutAnimation && (
                <div className={styles.icon}>
                  <img src={props.icon.file.url} alt={props.title} />
                </div>
              )}
              {!withoutAnimation && (
                <Reveal effect="fadeIn">
                  <div className={styles.icon}>
                    <img src={props.icon.file.url} alt={props.title} />
                  </div>
                </Reveal>
              )}
            </>
          )}
          <div className={styles.textContainer}>
            <div className={styles.top}>
              {withoutAnimation && (
                <>
                  <h6 className={cx("title", MAP_COLOR_TYPE[props.color])}>{props.title}</h6>
                  <p className={styles.text}>{props.text}</p>
                </>
              )}
              {!withoutAnimation && (
                <>
                  <Reveal effect="fadeIn">
                    <h6 className={cx("title", MAP_COLOR_TYPE[props.color])}>{props.title}</h6>
                  </Reveal>
                  <Reveal effect="fadeUp" duration={200}>
                    <p className={styles.text}>{props.text}</p>
                  </Reveal>
                </>
              )}
            </div>
          </div>
        </div>
        {props.buttonLink && (
          <div className={styles.linkContainer}>
            <ContentfulLink {...props.buttonLink} className={styles.cta}>
              {props.buttonLink.linkText}
              {props.horizontal && <RightCarrot />}
            </ContentfulLink>
          </div>
        )}
      </div>
    </div>
  )
}

export default Card

import React from "react"
import { graphql } from "gatsby"

import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"
import TwoColumnList from "components/twoColumnList"
import ContentfulLink from "components/contentfulLink"

import styles from "styles/sections/link-list-section.module.scss"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const LinkListSection = ({
  sectionHeading,
  sectionId,
  links,
  ctaLink,
  spacing,
  showCheckmarks,
  listStyle
}) => {
  const { topImage } = sectionHeading
  return (
    <section id={sectionId} className={cx({ small: spacing === "small" }, 'wrapper')}>
      <PaddedContent>
        <div className={styles.headlineWrapper}>
          {topImage?.file?.url && (
            <img src={topImage.file.url} className={cx('image')} alt={topImage.title} />
          )}
        </div>
        <div className={styles.flexContainer}>
          <div>
          {/* centered={isTablet} */}
            <SectionHeader {...sectionHeading} disableTopImage labelClass={cx('sectionLabel')} />
            {ctaLink && (
              <ContentfulLink {...ctaLink} className={styles.cta}>
                {ctaLink.linkText}
              </ContentfulLink>
            )}
          </div>
          {links && (
            <div className={cx('wrapListContainer', sectionHeading?.label && 'withLabel')}>
              <div className={cx('listContainer')}>
                <TwoColumnList
                  red
                  demibold
                  list={links}
                  verticalSpacing={"42px"}
                  hideCheckmarks={!showCheckmarks}
                  listStyle={listStyle}
                />
              </div>
            </div>
          )}
        </div>
      </PaddedContent>
    </section>
  )
}

export default LinkListSection

export const linkListSectionFragment = graphql`
  fragment LinkListSection on ContentfulLinkListSection {
    __typename
    id
    sectionId
    sectionHeading {
      ...SectionHeading
    }
    links {
      ... on ContentfulLink {
        ...Link
      }
      ... on ContentfulText {
        ...Text
      }
    }
    ctaLink {
      ...Link
    }
    showCheckmarks
    listStyle
  }
`

import React, { useState, useEffect, useRef, useMemo } from "react"
import Img from "gatsby-image"
import { CSSTransition } from "react-transition-group"
import { mod } from "variables.js"

import PaddedContent from "components/paddedContent"
import VideoButton from "components/videoButton"
import CarouselIndicators from "components/carouselIndicators"

import styles from "styles/sections/carousel/image-carousel.module.scss"
import styleVars from "styles/variables.scss"

const CarouselItem = ({
  stat,
  longQuote,
  name,
  position,
  video,
  backgroundImageDesktop,
  backgroundImageMobile,
  startCarousel,
  stopCarousel,
}) => {
  const breakpointTablet = parseInt(styleVars.breakpointTablet)
  const backgroundSources = [
    {
      ...backgroundImageMobile.fluid,
      media: `(max-width: ${breakpointTablet}px)`,
    },
    {
      ...backgroundImageDesktop.fluid,
      media: `(min-width: ${breakpointTablet + 1}px)`,
    },
  ]
  return (
    <div className={styles.carouselItem}>
      <Img
        fluid={backgroundSources}
        alt="Background image"
        className={styles.backgroundImage}
        style={{ position: "absolute", zIndex: "-1", top: "0" }}
      />
      <div className={styles.overlay} />
      <div className={styles.content}>
        <PaddedContent>
          <p className={styles.stat}>{stat}</p>
          <p className={styles.quote}>{longQuote.quote}</p>
          <p className={styles.name}>{name}</p>
          <p className={styles.position}>{position}</p>
          {video && (
            <VideoButton
              {...video}
              onShow={stopCarousel}
              onHide={startCarousel}
            />
          )}
        </PaddedContent>
      </div>
    </div>
  )
}

const ImageCarousel = ({ delay, carouselItems, sectionId }) => {
  const [active, setActive] = useState(0)
  const interval = useRef()
  const intervalTime = delay
  const swipePos = useRef()
  const inSwipe = useRef()
  const localCarouselItems = useMemo(() => Array.isArray(carouselItems) ? carouselItems : [], [carouselItems])
  const isMultipleItems = localCarouselItems.length > 1

  const startCarousel = () => {
    if (isMultipleItems) {
      interval.current = setInterval(() => incrementActive(), intervalTime)
    }
  }

  const stopCarousel = () => {
    if (isMultipleItems) {
      clearInterval(interval.current)
    }
  }

  const selectActive = (i) => {
    stopCarousel()
    setActive(i)
    startCarousel()
  }

  const incrementActive = () => {
    setActive((prevActive) => mod(prevActive + 1, localCarouselItems.length))
  }

  const decrementActive = () => {
    setActive((prevActive) => mod(prevActive - 1, localCarouselItems.length))
  }

  useEffect(() => {
    startCarousel()
    return () => {
      stopCarousel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTouchStart = (e) => {
    swipePos.current = e.nativeEvent.targetTouches[0].clientX
  }

  const handleTouchMove = (e) => {
    if (inSwipe.current) return
    var x = e.nativeEvent.targetTouches[0].clientX
    if (x > swipePos.current + 30) {
      stopCarousel()
      decrementActive()
      startCarousel()
      inSwipe.current = true
    } else if (x < swipePos.current - 30) {
      stopCarousel()
      incrementActive()
      startCarousel()
      inSwipe.current = true
    }
  }

  const handleTouchEnd = () => {
    inSwipe.current = false
  }

  return (
    <section
      className={styles.wrapper}
      id={sectionId}
      onTouchStart={isMultipleItems ? handleTouchStart : null}
      onTouchMove={isMultipleItems ? handleTouchMove : null}
      onTouchEnd={isMultipleItems ? handleTouchEnd : null}
    >
      {localCarouselItems.map((item, i) => (
        <CSSTransition
          in={i === active}
          timeout={1500}
          mountOnEnter
          classNames="background"
          key={i}
        >
          <CarouselItem
            {...item}
            startCarousel={startCarousel}
            stopCarousel={stopCarousel}
          />
        </CSSTransition>
      ))}
      {isMultipleItems && (
        <div className={styles.indicatorsWrapper}>
          <PaddedContent>
            <CarouselIndicators
              numItems={localCarouselItems.length}
              active={active}
              selectActive={(i) => selectActive(i)}
            />
          </PaddedContent>
        </div>
      )}
    </section>
  )
}

export default ImageCarousel

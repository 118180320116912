import React from "react"
import Img from "gatsby-image"
import Reveal from "react-reveal/Reveal"
import "styles/animations.scss"

import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"
import Form from "components/form"

import styles from "styles/sections/contact-form-section.module.scss"

const ContactFormSection = (props) => {
  const { title, subtext, image, sectionId } = props
  return (
    <section className={styles.container} id={sectionId}>
      <PaddedContent>
        <div className={styles.flex}>
          <div className={styles.left}>
            <div className={styles.headerContainer}>
              <SectionHeader title={title} subtext={subtext?.subtext} />
            </div>
            <Form {...props} />
          </div>
          {image && (
            <Reveal effect="fadeIn">
              <div className={styles.imageWrapper}>
                <div className={styles.imageInnerWrapper}>
                  <Img className={styles.image} fluid={image?.fluid} />
                </div>
              </div>
            </Reveal>
          )}
        </div>
      </PaddedContent>
    </section>
  )
}

export default ContactFormSection

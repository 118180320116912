import React from "react"
import { Link } from "gatsby"

import PaddedContent from "components/paddedContent"
import PageHeader from "components/pageHeader"

import styles from "styles/sections/hero/image-hero.module.scss"
import styleVars from "styles/variables.scss"
import { useBreakpoint } from "hooks/use-breakpoint"

const ImageHero = ({
  label,
  header,
  subheader,
  buttonText,
  buttonLink,
  heroImageMobile,
  heroImageDesktop,
}) => {
  const [isTablet, loaded] = useBreakpoint(styleVars.breakpointTablet)

  return (
    <section className={styles.wrapper}>
      <div className={styles.background} style={{
        backgroundImage: loaded && `url(${isTablet ? (heroImageMobile?.file?.url || heroImageDesktop?.file?.url) : heroImageDesktop?.file?.url})`
      }}>
        <div className={styles.overlay} />
      </div>
      <PaddedContent>
        <div className={styles.content}>
          <PageHeader
            white
            centered
            maxWidthTitle="800px"
            maxWidthSubtext="700px"
            label={label}
            title={header}
            subtext={subheader}
          />
          {buttonLink && buttonText && (
            <Link to={buttonLink} className={styles.cta}>
              {buttonText}
            </Link>
          )}
        </div>
      </PaddedContent>
    </section>
  )
}

export default ImageHero

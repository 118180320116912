import React from "react"
import { Link } from "gatsby"
import { useBreakpoint } from "hooks/use-breakpoint"
import { useContentfulSite } from "hooks/use-contentful-site"

import PaddedContent from "components/paddedContent"
import PageHeader from "components/pageHeader"
import Map from "components/map/map"

import styles from "styles/sections/hero/map-hero.module.scss"
import styleVars from "styles/variables.scss"
import Background from "images/hero-background.svg"
import BackgroundMobile from "images/hero-background-mobile.svg"
import icons from "helpers/icons"

const MapHero = ({
  label,
  header,
  buttonText,
  buttonLink,
  mapText,
  mapLocation,
  disableSocialShare
}) => {
  const [isTablet, loaded] = useBreakpoint(styleVars.breakpointTablet)
  const site = useContentfulSite()
  const listSocials = [
    { icon: 'youtube' },
    { icon: 'instagram' },
    { icon: 'twitter' },
    { icon: 'facebook' },
    { icon: 'linkedin' },
  ]
  let background
  background = loaded && (isTablet ? <BackgroundMobile /> : <Background />)
  return (
    <section className={styles.wrapper}>
      <div className={styles.background}>{background}</div>
      <PaddedContent>
        <div className={styles.content}>
          <div className={styles.mapTextContainer}>
            <PageHeader
              white
              maxWidthTitle="460px"
              maxWidthSubtext="460px"
              label={label}
              title={header}
              innerHTML={mapText?.childMarkdownRemark?.html || ''}
            />
            <div className={styles.listSocial}>
              {!disableSocialShare && listSocials.reduce((resultArr, social, socialIdx) => {
                if (site[`${social.icon}Link`]) {
                  resultArr.push(<a key={socialIdx} href={site[`${social.icon}Link`]} rel="noopener noreferrer" target="_blank">{icons[social.icon]}</a>)
                }
                return resultArr
              }, [])
              }
            </div>
            {buttonLink && buttonText && (
              <Link to={buttonLink} className={styles.cta}>
                {buttonText}
              </Link>
            )}
          </div>
          {mapLocation?.lat && mapLocation?.lon && (
            <div className={`${styles.mapContainer} no-alt`}>
              <Map position={{ lat: mapLocation.lat, lng: mapLocation.lon }} />
            </div>
          )}
        </div>
      </PaddedContent>
    </section>
  )
}

export default MapHero

import React from 'react'
import { graphql } from 'gatsby'
import PaddedContent from "components/paddedContent"
import styles from "styles/sections/image-block.module.scss"

const ImageBlock = ({ image }) => {
  return (
    <section className={styles.wrapper}>
      <PaddedContent>
        <img
          src={image?.file?.url}
          alt={image?.title}
        />
      </PaddedContent>
    </section>
  )
}

export default ImageBlock

export const imageBlock = graphql`
  fragment ImageBlock on ContentfulImageBlock {
    id
    image {
      file {
        url
      }
      title
    }
  }
`

import React from "react"
import Reveal from "react-reveal/Reveal"
import "styles/animations.scss"
import { TITLE_COLOR } from "constants.js"
import { graphql } from 'gatsby'

import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"
import ContentfulLink from "components/contentfulLink"
import RightCarrot from "images/icons/right-carrot.svg"

import styles from "styles/sections/checklist-section.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const ListItem = (props) => {
  const {
    __typename,
    verticalSpacing,
    linkText,
    title,
    text,
    titleColor
  } = props
  if (__typename === "ContentfulLink") {
    return (
      <li style={{ marginBottom: verticalSpacing }}>
        <ContentfulLink {...props}>
          <span>
            {linkText}
            <RightCarrot />
          </span>
        </ContentfulLink>
      </li>
    )
  }
  if (__typename === "ContentfulTextBlock") {
    return (
      <li style={{ marginBottom: verticalSpacing }}>
        <h3
          className={cx(styles.title, 'h5', 'semibold', TITLE_COLOR[titleColor])}
        >
          {title}
        </h3>
        <span>{text}</span>
      </li>
    )
  }
  return (
    <li style={{ marginBottom: verticalSpacing }}>
      <span>{text}</span>
    </li>
  )
}

const ChecklistSection = ({
  sectionHeading,
  title,
  description,
  checklist,
  link,
  backgroundType,
  checklistPosition
}) => {
  // checklistPosition: true => checklist in right, false => checklist in left
  const textEffect = checklistPosition ? 'fadeInLeft' : 'fadeInRight'
  const checklistEffect = checklistPosition ? 'fadeInRight' : 'fadeInLeft'
  return (
    <section
      className={cx("container", {
        white: backgroundType === "white",
        lightMint: backgroundType === "light mint",
        navy: backgroundType === "navy",
        teal: backgroundType === "teal",
      })}
    >
      <PaddedContent>
        {sectionHeading && (
          <SectionHeader
            centered={true}
            {...sectionHeading}
            maxWidthTitle="815px"
          />
        )}
        <div
          className={cx("flex", {
            reverse: !checklistPosition,
            hasSectionHeading: sectionHeading,
          })}
        >
          <Reveal effect={textEffect} duration={700}>
            <div className={styles.textWrapper}>
              {(title || description) && (
                <div>
                  {title && <h2 className={styles.title}>{title}</h2>}
                  {description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description.childMarkdownRemark.html,
                      }}
                      className={styles.description}
                    />
                  )}
                </div>
              )}
              {link && (
                <ContentfulLink {...link} className={styles.cta}>
                  {link.linkText}
                </ContentfulLink>
              )}
            </div>
          </Reveal>
          <Reveal effect={checklistEffect} duration={700}>
            <div className={styles.wrapChecklist}>
              {checklist && (
                  <ul className={styles.checklist}>
                    {checklist.map((item, i) => {
                      return (
                        <ListItem {...item} key={i} />
                      )
                    })}
                  </ul>
              )}
            </div>
          </Reveal>
        </div>
      </PaddedContent>
    </section>
  )
}

export default ChecklistSection

export const checklistSection = graphql`
  fragment ChecklistSection on ContentfulChecklistSection {
    __typename
    id
    sectionHeading {
      ...SectionHeading
    }
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    checklist {
      ... on Node {
        ... on ContentfulText {
          ...Text
        }
        ... on ContentfulLink {
          ...Link
        }
        ... on ContentfulTextBlock {
          title
          text
          titleColor
          icon {
            file {
              url
            }
          }
        }
      }
    }
    checklistPosition
    backgroundType
  }
`

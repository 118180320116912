import React, { useState } from "react"
import { graphql } from "gatsby"
import Reveal from "react-reveal/Reveal"
import ContentfulLink from "components/contentfulLink"
import WavyBackground from "components/wavyBackground"
import TealWaveBackground from "components/tealWaveBackground"
import { Dialog } from "@reach/dialog"
import styleVars from "styles/variables.scss"
import "styles/animations.scss"
import styles from "styles/sections/cta-section.module.scss"
import classNames from "classnames/bind"
import FormBlock from "components/formBlock"
import CloseIcon from "images/icons/close.svg"
import "styles/components/hubspotForm.scss"
import { textWithBreaklineHtml } from 'helpers/helpers'

const cx = classNames.bind(styles)

const CtaWrapper = (props) => {
  if (props.backgroundType === 'teal wave') {
    return (
      <TealWaveBackground>
        <CtaSection {...props} />
      </TealWaveBackground>
    )
  }
  if (
    props.backgroundType === "wavy" &&
    props.previousBackgroundType !== "light mint"
  ) {
    return (
      <WavyBackground bgType={props.bgType} isHideWave={props.isHideWave}>
        <CtaSection {...props} />
      </WavyBackground>
    )
  }
  if (
    props.backgroundType === "wavy mint top" ||
    (props.backgroundType === "wavy" &&
      props.previousBackgroundType === "light mint")
  ) {
    return (
      <WavyBackground bgType={props.bgType} isHideWave={props.isHideWave}>
        <CtaSection {...props} backgroundType="wavy" />
      </WavyBackground>
    )
  }
  return <CtaSection {...props} />
}

const HubspotForm = ({ formId, formTarget, portalId, subtext, title, text }) => {
  const [openPopup, setOpenPopup] = useState(false)

  const openHubspotForm = () => {
    setOpenPopup(true)
  }

  const closeHubspotForm = () => {
    setOpenPopup(false)
  }

  const onSubmit = () => {
    setTimeout(() => {
      setOpenPopup(false)
    }, 5000)
  }
  return <div>
    <button className={cx('cta')} onClick={openHubspotForm}>
      {text}
    </button>

    <Dialog
      isOpen={openPopup}
      style={{
        padding: 0,
        width: "92vw",
        maxWidth: "1200px",
        position: "relative",
        zIndex: styleVars.popupZ,
      }}
      aria-label="Required form to access article"
    >
      <div className="hubspot-form">
        <FormBlock
          {...{ formId, formTarget, portalId, subtext, title, showLoadding: true }}
          onSubmit={onSubmit}
        />
      </div>
      <button className="hubspot-close-btn"  onClick={closeHubspotForm}>
        <CloseIcon />
      </button>
    </Dialog>
  </div>
}
const CtaSection = ({
  sectionId,
  title,
  subtitle,
  link,
  backgroundType,
  overlapsWithPriorSection,
  popupForm
}) => {
  return (
    <section id={sectionId} className={cx("container", {
      wavy: backgroundType === "wavy",
      tealWave: backgroundType === 'teal wave',
      navy: backgroundType === 'navy NO waves',
      white: backgroundType === "white",
    })}>
      <div className={styles.textContainer}>
        <Reveal effect="fadeInUp" duration={1000}>
          <>
            <h2
              className={cx("title", { overlapsWithPriorSection })}
              dangerouslySetInnerHTML={{
                __html: textWithBreaklineHtml(title),
              }}
              />
            <p
              className={cx('text', link && !link.internalLink && !link.externalLink && 'noMargin')}
              dangerouslySetInnerHTML={{
                __html: textWithBreaklineHtml(subtitle),
              }}
            />
          </>
        </Reveal>
      </div>
      {!popupForm && <Reveal effect="fadeInUp" duration={1000}>
        <ContentfulLink {...link} className={styles.cta}>
          {link?.linkText}
        </ContentfulLink>
      </Reveal>}

      {popupForm && link.linkText && <HubspotForm {...popupForm} text={link.linkText} />}
    </section>
  )
}

export default CtaWrapper

export const ctaSectionFragment = graphql`
  fragment CtaSection on ContentfulCtaSection {
    __typename
    id
    sectionId
    title
    subtitle
    link {
      ...Link
    }
    backgroundType
    overlapsWithPriorSection
    popupForm {
      id
      formId
      formTarget
      portalId
      subtext {
        subtext
      }
      title
    }
  }
`

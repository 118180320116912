import React, { useState } from "react"
import VideoPopup from "./videoPopup"

import styles from "styles/components/video-button.module.scss"
import PlayButton from "images/icons/play-button.svg"

const VideoButton = ({ videoId, youtubeVideoId, onShow, onHide }) => {
  const [showPopup, setShowPopup] = useState(false)
  const open = () => {
    onShow()
    setShowPopup(true)
  }
  const close = () => {
    onHide()
    setShowPopup(false)
  }

  return (
    <>
      <button className={styles.playButton} onClick={open}>
        <PlayButton /> Watch Interview
      </button>
      <VideoPopup isOpen={showPopup} onDismiss={close} videoId={videoId} youtubeVideoId={youtubeVideoId} />
    </>
  )
}

export default VideoButton

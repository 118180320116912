import React, { useEffect, useState } from "react"
import Reveal from "react-reveal/Reveal"
import "styles/animations.scss"

import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"
import DialogBio from 'components/dialogBio'

import styles from "styles/sections/grid/leadership-section.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const LeaderBlock = (data) => {
  const { name, role, avatar, openDialog } = data
  return (
    <div className={styles.wrapLeaderBlock}>
      <Reveal effect="fadeUp">
        <div className={styles.leaderBlock}>
          {(
            <div
              className={styles.avatar}
              style={{ backgroundImage: `url(${avatar?.file?.url})` }}
              onClick={() => openDialog()}
              onKeyDown={() => openDialog()}
              role="button"
              tabIndex={0}
            />
          )}
          <div>
            <h3 className={styles.name}>{name}</h3>
            <h4 className={styles.role}>{role}</h4>
            <button onClick={() => openDialog()}>READ BIO</button>
          </div>
        </div>
      </Reveal>
    </div>
  )
}

const getRichTextValue = richText => richText?.json?.content && richText?.json?.content[0]?.content && richText?.json?.content[0]?.content[0]?.value

const LeadershipSection = ({
  sectionHeading,
  gridItems,
  sectionId,
  backgroundType,
}) => {
  const [isShowDialogBio, setIsShowDialogBio] = useState(false)
  const [selectedBio, setSelectedBio] = useState(null)
  useEffect(() => {
    const $html = document.querySelector("html")
    if (isShowDialogBio) {
      $html.classList.add("preventBodyScroll")
    } else {
      $html.classList.remove("preventBodyScroll")
    }
  }, [isShowDialogBio])
  const openDialog = bioObj => {
    setSelectedBio(bioObj)
    setIsShowDialogBio(true)
  }
  return (
    <section
      className={cx("container", {
        white: backgroundType === "white",
        lightMint: backgroundType === "light mint",
        mint: backgroundType === "mint",
      })}
      id={sectionId}
    >
      <PaddedContent>
        {sectionHeading && sectionHeading.name && (
          <div className={styles.header}>
            <SectionHeader
              {...sectionHeading}
            />
          </div>
        )}
        {gridItems && (
          <div className={styles.leaderBlockGrid}>
            {gridItems.map((gridItem, i) => {
              const { childContentfulLeadershipDescriptionRichTextNode, childContentfulLeadershipNameleadershipRichTextNode, childContentfulLeadershipPositionRichTextNode, image } = gridItem
              gridItem.name = getRichTextValue(childContentfulLeadershipNameleadershipRichTextNode)
              gridItem.role = getRichTextValue(childContentfulLeadershipPositionRichTextNode)
              gridItem.avatar = image
              gridItem.text = childContentfulLeadershipDescriptionRichTextNode.json
              return <LeaderBlock {...gridItem} key={i} openDialog={() => openDialog(gridItem)} />
            })}
          </div>
        )}
      </PaddedContent>
      <DialogBio show={isShowDialogBio} close={() => setIsShowDialogBio(false)} selectedBio={selectedBio} />
    </section>
  )
}

export default LeadershipSection

import React from "react"

import SectionHeader from "components/sectionHeader"
import ArticlesGrid from "components/articlesGrid"

import styles from "styles/sections/grid/team-section.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const TeamSection = ({
  sectionHeading,
  gridItems,
  sectionId,
  backgroundType,
}) => {
  return (
    <section
      className={cx("container", {
        white: backgroundType === "white",
        lightMint: backgroundType === "light mint",
        mint: backgroundType === "mint",
      })}
      id={sectionId}
    >
      <div className={styles.headerContainer}>
        <SectionHeader
          {...sectionHeading}
          centered
          maxWidthSubtext="690px"
          demibold
        />
      </div>
      <ArticlesGrid team articles={gridItems} />
    </section>
  )
}

export default TeamSection

import React, { useEffect, useRef, useState } from "react"
import { graphql } from 'gatsby'
import PaddedContent from "components/paddedContent"
import Reveal from 'react-reveal/Reveal'
import { useBreakpoint } from "hooks/use-breakpoint"

import styles from "styles/sections/three-tier-data-section.module.scss"
import classNames from "classnames/bind"
import GlobalProductDataSvg from 'images/tier-icons/global-product-data.svg'
import GlobalProductDataActiveSvg from 'images/tier-icons/global-product-data-active.svg'
import InventoryDataSvg from 'images/tier-icons/inventory-data.svg'
import InventoryDataActiveSvg from 'images/tier-icons/inventory-data-active.svg'
import SalesDataSvg from 'images/tier-icons/sales-data.svg'
import SalesDataActiveSvg from 'images/tier-icons/sales-data-active.svg'
import TopLock from 'images/icons/topLock.svg'
import BottomLock from 'images/icons/bottomLock.svg'
import IconUnlocked from 'images/icons/icon-unlocked.svg'
import BarGroup from "images/tierBarGroup.svg"

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const cx = classNames.bind(styles)

const SectionLine = ({
  width,
  height,
  isVertical,
  active,
  margin,
  position,
  top,
  left,
  cls
}) => {
  const styleObject = {
    width,
    height,
    margin,
    position,
    top,
    left
  }
  let borderStyle = '2px dotted rgb(112, 112, 112, 0.5)'

  if (active) {
    borderStyle = '5px solid #041E42'
  }
  if (isVertical) {
    styleObject.borderRight = borderStyle
  } else {
    styleObject.borderTop = borderStyle
  }
  let classAttr = ' hidden-print'
  if (cls){
    classAttr = cls + classAttr
  }
  return (
    <div style={styleObject} className={classAttr}></div>
  )
}
const SectionActiveLine = ({
  width,
  height,
  isVertical,
  active,
  margin,
  top,
  left,
  cls
}) => {
  const styleObject = {
    width,
    height,
    margin,
    position: 'absolute',
    top,
    left: '50%',
    transform: 'translateX(-50%)'
  }
  let borderStyle = '5px solid #041e42'
  if (isVertical) {
    styleObject.borderRight = borderStyle

  } else {
    styleObject.borderTop = borderStyle
  }
  
  if (active) {
    styleObject.width = width
    styleObject.height = height
    styleObject.opacity = 1
    styleObject.transition = 'all 0.3s'
  } else {
    styleObject.width = 0
    styleObject.height = 0
    styleObject.opacity = 0
    styleObject.transition = 'unset'
  }
  let classAttr = ' hidden-print'
  if (cls){
    classAttr = cls + classAttr
  }
  return (
    <div style={styleObject} className={classAttr}></div>
  )
}
const SectionLine2 = ({
  width,
  height,
  lineLeft,
  active,
  margin,
  position,
  top,
  left,
  cls
}) => {
  const styleObject = {
    width,
    height,
    margin,
    position,
    top,
    left
  }
  let borderStyle = '2px dotted rgb(112, 112, 112, 0.5)'
  if (active) {
    borderStyle = '5px solid #041E42'
  }
  styleObject.borderBottom = borderStyle
  if (lineLeft) {
    styleObject.borderLeft = borderStyle
  } else {
    styleObject.borderRight = borderStyle
  }
  let classAttr = ' hidden-print'
  if (cls){
    classAttr = cls + classAttr
  }
  return (
    <div style={styleObject} className={classAttr}></div>
  )
}
const SectionActiveLine2 = ({
  width,
  height,
  lineLeft,
  active,
  margin,
  top,
  left,
  cls
}) => {
  const styleObject = {
    margin,
    position: 'absolute',
    top,
    left,
  }
  const borderStyle = '5px solid #041E42'
  styleObject.borderBottom = borderStyle

  if (lineLeft) {
    styleObject.borderLeft = borderStyle
    styleObject.left = '50%'

  } else {
    styleObject.borderRight = borderStyle
    styleObject.right = '50%'
    
  }
  if (active) {
    styleObject.width = width
    styleObject.height = height
    styleObject.opacity = 1
    styleObject.transitionProperty = 'height, width';
    styleObject.transitionDuration = '0.3s';
    styleObject.transitionDelay = '0s, 0.2s';
  } else {
    styleObject.width = 0
    styleObject.height = 0
    styleObject.opacity = 0
    styleObject.transition = 'unset'
  }
  let classAttr = ' hidden-print'
  if (cls){
    classAttr = cls + classAttr
  }
  return (
    <div style={styleObject} className={classAttr}></div>
  )
}


const TierDataItem = ({ image, activeImage, active, text, onClick }) => {
  const onClickTierDataItem = () => {
    if (typeof onClick === 'function') {
      onClick()
    }
  }
  return (
    <div className={cx('tierDataItem')} onClick={onClickTierDataItem} role="presentation">
        <div className={cx('innerContent', active && 'active', 'cursorPointer')}>
          <div>
            {active ? activeImage : image}
          </div>
          <div className={cx('text')}>
            {text}
          </div>
        </div>
    </div>
  )
}


const ThreeTierDataSection = ({
  header,
  childContentfulThreeTierDataGlobalProductDataTextNode,
  childContentfulThreeTierDataSalesDataTextNode,
  childContentfulThreeTierDataInventoryDataTextNode,
}) => {
  const [isCustomSlideBreakpoint] = useBreakpoint(489)
  const [isTablet] = useBreakpoint(767)

  const [activeTierData, setActiveTierData] = useState(0)
  const tierData = [
    {
      content: childContentfulThreeTierDataGlobalProductDataTextNode?.childMarkdownRemark?.html
    },
    {
      content: childContentfulThreeTierDataSalesDataTextNode?.childMarkdownRemark?.html
    },
    {
      content: childContentfulThreeTierDataInventoryDataTextNode?.childMarkdownRemark?.html
    },
  ]
  const handleChangeData = index => {
    setActiveTierData(index)
  }
  const carouselResponsive = {
    mobile: {
      breakpoint: { max: 767, min: 490 },
      items: 1,
    },
    mobileSmall: {
      breakpoint: { max: 489, min: 0 },
      items: 1,
      // partialVisibilityGutter: 95
    },
  };
  const lockRef = useRef(null)
  useEffect(() => {
    const handleScroll = () => {
      if (lockRef.current && !lockRef.current.classList.contains(cx('active')) && (lockRef.current.getBoundingClientRect().bottom + 100) <= window.innerHeight) {
        lockRef.current.classList.add(cx('active'))
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lockRef])
  return (
    <section className={cx('wrapper')}>
      <PaddedContent>
        <div className={cx('relative')}>
          <div className={cx('sectionBg')}>
            <BarGroup />
          </div>
        </div>
      </PaddedContent>
      <PaddedContent>
        <div>
        <Reveal effect="fadeInUp">
          <div className={cx('relative')}>
            <SectionLine isVertical width={'1px'} height={'117px'} margin={'0 auto'} />
            <h2 className={cx('title')}>{header}</h2>
          </div>
        </Reveal>
          {!isTablet && (
            <>
              <div className={cx('relative', 'w70per', 'mxAuto', 'textCenter')}>
                <SectionLine isVertical width="1px" height="50px" margin="0 auto" />
                <div className={cx('wrapLock')}>
                  <div ref={lockRef} className={cx('lock')}>
                    <TopLock className={cx('topLock')} />
                    <BottomLock className={cx('bottomLock')} />
                  </div>
                </div>
                <SectionLine width="100%" height="1px" margin="-40px 0 0 0" />
              </div>
              <Reveal wait={2000} effect="fadeInDown">
                <>
                  <div className={cx('flex', 'justifyBetween', 'threeItem')}>
                    <div className={cx('textCenter', 'w30per')}>
                      <SectionLine isVertical width="1px" height="85px" margin="0 auto" />
                      <TierDataItem active={activeTierData === 0} image={<GlobalProductDataSvg />} activeImage={isTablet ? <GlobalProductDataSvg /> : <GlobalProductDataActiveSvg />} text="Global Product Data" onClick={() => handleChangeData(0)} />
                      <div className={cx('relative')}>
                        <SectionActiveLine2 active={activeTierData === 0} lineLeft width="50%" height="85px" cls={cx('leftLine')}/>
                        <SectionLine2 lineLeft width="50%" height="85px" margin="0 0 0 auto" cls={cx('leftLine')} />
                      </div>
                    </div>
                    <div className={cx('textCenter', 'w30per')}>
                      <SectionLine isVertical width="1px" height="85px" margin="0 auto" />
                      <TierDataItem active={activeTierData === 1} image={<SalesDataSvg />} activeImage={isTablet ? <SalesDataSvg /> : <SalesDataActiveSvg />} text="Sales Data" onClick={() => handleChangeData(1)} />
                      <div className={cx('relative')}>
                        <SectionActiveLine active={activeTierData === 1} isVertical width="1px" height="35px" />
                        <SectionLine isVertical width="1px" height="35px" margin="0 auto" />
                      </div>
                    </div>
                    <div className={cx('textCenter', 'w30per')}>
                      <SectionLine isVertical width="1px" height="85px" margin="0 auto" />
                      <TierDataItem active={activeTierData === 2} image={<InventoryDataSvg />} activeImage={isTablet ? <InventoryDataSvg /> : <InventoryDataActiveSvg />} text="Inventory Data" onClick={() => handleChangeData(2)} />
                      <div className={cx('relative')}>
                        <SectionActiveLine2 active={activeTierData === 2} width="50%" height="85px" cls={cx('rightLine')} />
                        <SectionLine2 width="50%" height="85px" margin="0 auto 0 0 "  cls={cx('rightLine')}/>
                      </div>
                    </div>
                  </div>
                  <div className={cx('relative', 'w70per', 'mxAuto')}>
                    {tierData.map((data, index) => (
                      <>
                        {activeTierData === index && (
                          <Reveal duration={400} effect="fadeIn">
                            <div className={cx('dataContent')}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data.content,
                                }}
                              />
                            </div>
                          </Reveal>
                        )}
                      </>
                    ))}
                  </div>
                </>
              </Reveal>
            </>
          )}
        </div>
      </PaddedContent>
      {isTablet && (
        <>
          <div className={cx('relative', 'mxAuto', 'textCenter','lockIcon')}>
            <SectionLine isVertical width="1px" height="50px" margin="0 auto" />
            <IconUnlocked />
          </div>
          <PaddedContent>
            <div className={cx('slidePr')}>
            <Carousel
              className={cx('slide')}
              responsive={carouselResponsive}
              itemClass={cx('textCenter', 'slideItem')}
              arrows={false}
              centerMode={false}
              partialVisible={true}
              containerClass={cx(!isCustomSlideBreakpoint && 'containerCustom', isCustomSlideBreakpoint && 'containerCustomMobileSmall')}
              afterChange={(previousSlide, { currentSlide, onMove }) => handleChangeData(Number(currentSlide))}
              focusOnSelect
            >
              <TierDataItem active={activeTierData === 0} image={<GlobalProductDataSvg />} activeImage={isTablet ? <GlobalProductDataSvg /> : <GlobalProductDataActiveSvg />} text="Global Product Data" />
              <TierDataItem active={activeTierData === 1} image={<SalesDataSvg />} activeImage={isTablet ? <SalesDataSvg /> : <SalesDataActiveSvg />} text="Sales Data" />
              <TierDataItem active={activeTierData === 2} image={<InventoryDataSvg />} activeImage={isTablet ? <InventoryDataSvg /> : <InventoryDataActiveSvg />} text="Inventory Data" />
            </Carousel>
            
            </div>
           
            <div className={cx('relative', 'mxAuto')}>
              <div className={cx('dataContent')}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: tierData[activeTierData].content,
                  }}
                />
              </div>
            </div>
          </PaddedContent>
        </>
      )}
    </section>
  )
}

export default ThreeTierDataSection

export const threeTierData = graphql`
  fragment ThreeTierData on ContentfulThreeTierData {
    header
    id
    childContentfulThreeTierDataGlobalProductDataTextNode {
      childMarkdownRemark {
        html
      }
    }
    childContentfulThreeTierDataInventoryDataTextNode {
      childMarkdownRemark {
        html
      }
    }
    childContentfulThreeTierDataSalesDataTextNode {
      childMarkdownRemark {
        html
      }
    }
  }
`

import React from "react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"
import mapMarker from "images/icons/map-marker.png"
// https://mapstyle.withgoogle.com/
const mapStyles = require("./map-styles.json")

const Map = ({ position, mapUrl }) => {
  return (
    <LoadScript googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
        zoom={13}
        center={position}
        options={{
          styles: mapStyles,
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
      >
        <Marker
          position={position}
          icon={mapMarker}
          onClick={() => {
            if (mapUrl) {
              window.open(mapUrl, "_blank")
            }
          }}
        ></Marker>
      </GoogleMap>
    </LoadScript>
  )
}

export default Map

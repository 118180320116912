import React from "react"
import { useBreakpoint } from "hooks/use-breakpoint"
import Reveal from 'react-reveal/Reveal'
import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"
import ContentfulLink from "components/contentfulLink"
import LinkTextWithChev from "components/linkTextWithChev"
import styleVars from "styles/variables.scss"
import tealWaveBackgroundNewImgMobile from "images/teal-wave-bg-new-mobile.png"
import tealWaveBackgroundNewImg from "images/teal-wave-bg-new.png"

import styles from "styles/sections/grid/three-cols-image-and-text-section.module.scss"
import classNames from "classnames/bind"
import { isURL } from "variables.js"
const cx = classNames.bind(styles)

const TextBlock = ({ longText, longTextBlockButtonLink, longTextBlockButtonText, icon, title }) => {
  const ctaLink = {
    linkText: longTextBlockButtonText
  }
  if (isURL(longTextBlockButtonLink)) {
    ctaLink.externalLink = longTextBlockButtonLink
  } else {
    ctaLink.internalLink = longTextBlockButtonLink
  }
  return (
    <div className={styles.textBlock}>
      <Reveal effect="fadeInUp" duration={1000}>
        <div className={styles.image}>
          <img src={icon?.file?.url} alt={title} />
        </div>
      </Reveal>
      <Reveal effect="fadeInUp" duration={1000}>
        <div className={styles.contentDescription}>
          <div className={styles.description}
            dangerouslySetInnerHTML={{
              __html: longText?.childMarkdownRemark?.html,
            }}
          />
          <div className={styles.button}>
          <ContentfulLink {...ctaLink}>
            <LinkTextWithChev text={ctaLink.linkText} />
          </ContentfulLink>
          </div>
        </div>
      </Reveal>
    </div>
  )
}

const ThreeColsImageTextSection = ({
  sectionHeading,
  gridItems,
  sectionId,
  backgroundType,
}) => {
  const [isCustomBreakpoint, loaded] = useBreakpoint(991)
  const [isTablet] = useBreakpoint(styleVars.breakpointTablet, -1)
  return (
    <section
      className={cx("container", {
        white: backgroundType === 'white',
        tealWaveWhite: (!isCustomBreakpoint && backgroundType === 'teal wave'),
        lightMint: backgroundType === "light mint",
        mint: backgroundType === "mint"
      })}
      id={sectionId}
    >
      {backgroundType === 'teal wave' && (
        <div className={cx('tealWaveBackground')} style={{ backgroundImage: loaded && `url(${isCustomBreakpoint ? tealWaveBackgroundNewImgMobile : tealWaveBackgroundNewImg})` }} />
      )}
      <Reveal effect="fadeInUp" duration={1000}>
        <div className={cx(loaded && isCustomBreakpoint && backgroundType === 'teal wave' && 'tealWave') + ' ' + styles.relative + ' ' + styles.wrapper}>
          <PaddedContent>
            {sectionHeading && (
              <div className={styles.header}>
                <SectionHeader
                  {...sectionHeading}
                  centered={!isTablet}
                />
              </div>
            )}
            {gridItems && (
              <div className={styles.textBlockGrid}>
                {gridItems.map((textBlock, i) => {
                  return <TextBlock {...textBlock} key={i} />
                })}
              </div>
            )}
          </PaddedContent>
        </div>
      </Reveal>
    </section>
  )
}

export default ThreeColsImageTextSection

import React from "react"
import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"
import Video from "components/video"

import styleVars from "styles/variables.scss"
import styles from "styles/components/video-popup.module.scss"
import Close from "images/icons/close-white.svg"

const VideoPopup = ({ isOpen, onDismiss, videoId, youtubeVideoId }) => {
  return (
    <Dialog
      style={{
        backgroundColor: "black",
        padding: 0,
        margin: "8vh auto",
        width: "85vw",
        zIndex: styleVars.popupZ,
        position: "relative",
      }}
      isOpen={isOpen}
      onDismiss={onDismiss}
      aria-label="Video popup"
    >
      <Video videoId={videoId} youtubeVideoId={youtubeVideoId} popup closePopup={onDismiss} />
      <button className={styles.closeButton} onClick={onDismiss}>
        <Close />
      </button>
    </Dialog>
  )
}

export default VideoPopup

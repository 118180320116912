import React from "react"
import Reveal from "react-reveal/Reveal"
import "styles/animations.scss"
import { TITLE_COLOR } from "constants.js"

import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"

import styles from "styles/sections/grid/three-cols-text-section.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const TextBlock = ({ title, longText, titleColor, icon }) => {
  return (
    <div className={styles.textBlock}>
      {icon?.file?.url && (
        <Reveal effect="fadeIn">
          <div className={styles.icon}>
            <img src={icon.file.url} alt={title} />
          </div>
        </Reveal>
      )}
      <div>
        <Reveal effect="fadeIn">
          <h3
            className={cx(styles.title, TITLE_COLOR[titleColor])}
          >
            {title}
          </h3>
        </Reveal>
        <Reveal effect="fadeUp" duration={200}>
          <div
            dangerouslySetInnerHTML={{
              __html: longText?.childMarkdownRemark?.html,
            }}
          />
        </Reveal>
      </div>
    </div>
  )
}

const ThreeColsTextSection = ({
  sectionHeading,
  gridItems,
  sectionId,
  backgroundType,
}) => {
  return (
    <section
      className={cx("container", {
        white: backgroundType === "white",
        lightMint: backgroundType === "light mint",
        mint: backgroundType === "mint",
      })}
      id={sectionId}
    >
      <PaddedContent>
        {sectionHeading && (
          <div className={styles.header}>
            <SectionHeader
              {...sectionHeading}
            />
          </div>
        )}
        {gridItems && (
          <div className={styles.textBlockGrid}>
            {gridItems.map((textBlock, i) => {
              return <TextBlock {...textBlock} key={i} />
            })}
          </div>
        )}
      </PaddedContent>
    </section>
  )
}

export default ThreeColsTextSection

import React, { useLayoutEffect, useRef } from "react"
import { graphql } from 'gatsby'
import PaddedContent from "components/paddedContent"
import styles from "styles/sections/home-hero-banner-section.module.scss"
import RichText from "components/richText"

import { useBreakpoint } from "hooks/use-breakpoint"

import styleVars from "styles/variables.scss"

import iconSupMB from "images/icon-supplychain-mb.png"
import Reveal from 'react-reveal/Reveal';
import DotImg from 'images/icons/dot.png'
import lottie from 'lottie-web'
import animationData from 'images/lottie/erp-loop/erp-loop.json'

const ErpSolutionsSection = ({
  header,
  markdown
}) => {
  const [isTablet, loaded] = useBreakpoint(styleVars.breakpointTablet)
  const richText = markdown?.json || ''

  let animationContainerRef = useRef();
  useLayoutEffect(() => {
    let anim
    if (!isTablet && loaded && animationContainerRef.current) {
      anim = lottie.loadAnimation({
        container: animationContainerRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData
      });
    }
    return () => anim?.destroy();
  }, [animationContainerRef, isTablet, loaded]);

  return (
    <section className={styles.moduleBgblue}>
      <PaddedContent>
        <Reveal effect="fadeInUp">
          <div className={styles.BgblueTitle}>
            <h3>{header}</h3>
          </div>
        </Reveal>
        {/* <Reveal effect="fadeInUp">
          <div className={styles.contentImgSup}>
            {loaded && (
              isTablet ? (
                <img
                  className={styles.iconSupMB}
                  src={iconSupMB}
                  alt="iconSupMB"
                />
              ) : (
                <div className={styles.iconSup} ref={animationContainerRef} />
              )
            )}
          </div>
        </Reveal> */}
        <Reveal effect="fadeInUp">
          <div className={styles.supDescription}>
            <RichText text={richText} />
          </div>
        </Reveal>
      </PaddedContent>
      <div className={styles.box}>
        <div className={styles.dot} style={{ backgroundImage: `url(${DotImg})` }}></div>
      </div>
    </section>
  )
}

export default ErpSolutionsSection

export const erpSolutionsSection = graphql`
  fragment ErpSolutionsSection on ContentfulErpSolutionsSection {
    __typename
      header
      markdown {
        json
      }
  }
`

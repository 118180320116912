import React from "react"
import { graphql } from 'gatsby'
import Reveal from "react-reveal/Reveal"
import FilteredBoardsGrid from "../components/filteredBoardsGrid"
import PaddedContent from "components/paddedContent"
import styles from "styles/components/board-grid.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const GreenhouseSection = ({ title }) => {

  return (
    <section className={cx("container")}>
      <PaddedContent>
        <Reveal effect="fadeInLeft" duration={700}>
          <div>{title && <h2>{title}</h2>}</div>
        </Reveal>
        <div>
          <FilteredBoardsGrid></FilteredBoardsGrid>
        </div>
      </PaddedContent>
    </section>
  )
}

export default GreenhouseSection

export const greenhouseSection = graphql`
  fragment GreenhouseSection on ContentfulGreenhouseSection {
    title
  }
`
import React from "react"
import styles from "../styles/components/text-ani.module.scss"
const TextAniHero = ({text}) => {
  const listArrText = text.split('|')
  const listText = listArrText.map((textItem,index) =>{
    const ani = {
      animationDuration: listArrText.length*3+'s',
      animationDelay: index*3+'s'
    }
    return <span  key={index} className={`${styles.rote}`} style={ani}>{textItem}</span>
  });
  return (
    <span className={`${styles.rotePr}`}>
      {listText}&nbsp;
    </span>
  )
}

export default TextAniHero

import React, { useState, useMemo } from "react"
import Img from "gatsby-image"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { useBreakpoint } from "hooks/use-breakpoint"
import Reveal from 'react-reveal/Reveal';
import SectionHeader from "components/sectionHeader"
import PaddedContent from "components/paddedContent"

import ChevLeft from "images/icons/chev-left.svg"
import ChevRight from "images/icons/chev-right.svg"

import styles from "styles/sections/carousel/brand-carousel.module.scss"
import styleVars from "styles/variables.scss"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 2,
    slidesToSlide: 2,
  },
}

// const CustomLeftArrow = ({ onClick, ...rest }) => {
//   return (
//     <button className={styles.leftArrow} onClick={() => onClick()}>
//       <Arrow />
//     </button>
//   )
// }

// const CustomRightArrow = ({ onClick, ...rest }) => {
//   return (
//     <button className={styles.rightArrow} onClick={() => onClick()}>
//       <Arrow />
//     </button>
//   )
// }

const ButtonGroup = ({ next, previous, goToSlide, onClickChangeSlideCallback, customClass, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest
  const onClickPrev = () => {
    previous()
    if (typeof onClickChangeSlideCallback === 'function') {
      onClickChangeSlideCallback()
    }
  }
  const onClickNext = () => {
    next()
    if (typeof onClickChangeSlideCallback === 'function') {
      onClickChangeSlideCallback()
    }
  }
  return (
    <div className={['brand-carousel-button-group', customClass].join(' ')}>
      <button
        aria-label="Move Carousel Left"
        className={cx("leftArrow", { disable: currentSlide === 0 })}
        onClick={() => onClickPrev()}
      >
        <ChevLeft />
      </button>
      <button
        aria-label="Move Carousel Right"
        className={cx("rightArrow", {
          disable: currentSlide === totalItems - slidesToShow,
        })}
        onClick={() => onClickNext()}
      >
        <ChevRight />
      </button>
    </div>
  )
}

const BrandCarousel = ({
  sectionHeading,
  delay,
  backgroundType,
  carouselLogos,
  sectionId,
}) => {
  const localCarouselLogos = useMemo(() => (Array.isArray(carouselLogos) ? carouselLogos : []).filter(logo => logo?.fluid), [carouselLogos])
  const [isTablet] = useBreakpoint(styleVars.breakpointTablet, -1)

  const [is2Item] = useBreakpoint(responsive.mobile.breakpoint.max)
  const [is3Item] = useBreakpoint(responsive.tablet.breakpoint.max)
  let currentSlidesToShowValue = 5
  if (is2Item) {
    currentSlidesToShowValue = 2
  } else if (is3Item) {
    currentSlidesToShowValue = 3
  }
  const justEnoughItems = localCarouselLogos.length <= currentSlidesToShowValue

  const [autoPlay, setAutoPlay] = useState(true)
  const autoPlaySpeed = delay
  let timeout
  const onClickChangeSlideCallback = () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    setAutoPlay(false)
    timeout = setTimeout(() => setAutoPlay(true), 500)
  }

  return (
    <section
      className={cx("container", { white: backgroundType === "white" })}
      id={sectionId}
    > <Reveal effect="fadeInUp" duration={1000}>
      <PaddedContent>
        <SectionHeader
          title={sectionHeading.title}
          label={sectionHeading.label}
          subtext={sectionHeading.subtext}
          centered
          maxWidthTitle="unset"
          maxWidthSubtext="888px"
        />
      </PaddedContent>
      <div className={styles.carouselWrapper}>
        <Carousel
          responsive={responsive}
          containerClass={["brand-carousel-container", justEnoughItems && 'center'].join(' ')}
          arrows={false}
          infinite={!justEnoughItems}
          autoPlay={!justEnoughItems && autoPlay}
          autoPlaySpeed={autoPlaySpeed}
          draggable={false}
          swipeable={true}
          minimumTouchDrag={10}
          customButtonGroup={<ButtonGroup customClass={justEnoughItems && cx('hidden')} onClickChangeSlideCallback={onClickChangeSlideCallback} />}
          renderButtonGroupOutside
          itemClass="brand-carousel-item"
          centerMode={justEnoughItems || isTablet}
        >
          {localCarouselLogos.map((logo, i) => logo?.fluid && (
            <div className={styles.logoContainer} key={i}>
              <Img
                className={styles.logo}
                style={{ height: "100%" }}
                imgStyle={{ objectFit: "contain" }}
                fluid={logo.fluid}
                alt={logo.title}
              />
              <div className={styles.caption}>{logo.description}</div>
            </div>
          ))}
        </Carousel>
      </div>
      </Reveal>
    </section>
  )
}

export default BrandCarousel

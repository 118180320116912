import React from "react"
import Reveal from "react-reveal/Reveal"
import { graphql } from 'gatsby'
import "styles/animations.scss"

import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"
import TwoColumnList from "components/twoColumnList"
import Video from "components/video"
import ContentfulLink from "components/contentfulLink"

import styles from "styles/sections/image-and-text-section.module.scss"
import classNames from "classnames/bind"
import HubspotForm from "./hubspotForm"
const cx = classNames.bind(styles)

const ImageAndTextSection = ({
  sectionId,
  sectionHeading,
  label,
  title,
  description,
  image,
  checklist,
  video,
  imagePosition,
  link,
  popupForm,
  backgroundType,
}) => {
  // imagePosition: true => image in right, false => image in left
  const textEffect = imagePosition ? 'fadeInLeft' : 'fadeInRight'
  const imageEffect = imagePosition ? 'fadeInRight' : 'fadeInLeft'
  const showContent = label || title || description || image || video
  return (
    <section
      id={sectionId}
      className={cx("container", {
        white: backgroundType === "white",
        lightMint: backgroundType === "light mint",
        navy: backgroundType === "navy",
        teal: backgroundType === "teal",
      })}
    >
      <PaddedContent>
        {sectionHeading && (
          <SectionHeader
            centered={true}
            {...sectionHeading}
            containerClass={cx('header')}
            maxWidthTitle="815px"
          />
        )}
        {showContent && <div
          className={cx("flex", {
            right: !imagePosition && (image || video),
            hasSectionHeading: sectionHeading,
          })}
        >
          {(image || video) && (
            <Reveal effect={textEffect} duration={700}>
              <div className={styles.imageWrapper}>
                {image?.file?.url && <img src={image.file.url} alt={image.title} />}
                {video && (
                  <Video className={`${styles.video} no-alt`} {...video} />
                )}
              </div>
            </Reveal>
          )}
          <Reveal effect={imageEffect} duration={700}>
            <div className={cx('text', !(image || video) && 'withoutImage')}>
              {(label || title || description) && (
                <div>
                  {label && <div className={styles.label}>{label}</div>}
                  {title && <h2 className={cx("title", "h3")}>{title}</h2>}
                  {description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description.childMarkdownRemark.html,
                      }}
                      className={styles.description}
                    />
                  )}
                </div>
              )}
              {checklist && (
                <div className={styles.listWrapper}>
                  <TwoColumnList red oneColumn list={checklist} />
                </div>
              )}
              {!popupForm && link && (
                <ContentfulLink {...link} className={styles.cta}>
                  {link.linkText}
                </ContentfulLink>
              )}
              {popupForm && link && link.linkText && <HubspotForm {...popupForm} text={link.linkText} customerStyle={cx(styles.cta)}/>}
            </div>
          </Reveal>
        </div>}
      </PaddedContent>
    </section>
  )
}

export default ImageAndTextSection

export const imageAndTextSection = graphql`
  fragment ImageAndTextSection on ContentfulImageAndTextSection {
    __typename
    id
    sectionId
    sectionHeading {
      ...SectionHeading
    }
    label
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    link {
      ...Link
    }
    popupForm {
      id
      formId
      formTarget
      portalId
      subtext {
        subtext
      }
      title
    }
    image {
      file {
        url
      }
      title
    }
    video {
      ...Video
    }
    checklist {
      ... on ContentfulText {
        ...Text
      }
      ... on ContentfulLink {
        ...Link
      }
    }
    imagePosition
    backgroundType
  }
`

import React from "react"
import Reveal from "react-reveal/Reveal"
import { graphql } from 'gatsby'
import "styles/animations.scss"

import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"
import TwoColumnList from "components/twoColumnList"
import Video from "components/video"
import ContentfulLink from "components/contentfulLink"

import styles from "styles/sections/form-image-and-text-section.module.scss"
import classNames from "classnames/bind"
import FormBlockSmall from "components/formBlockSmall"
const cx = classNames.bind(styles)

const FormWidthImageAndTextSection = ({
  sectionId,
  sectionHeading,
  label,
  title,
  description,
  image,
  checklist,
  video,
  link,
  requiredForm,
  backgroundType,
}) => {
  const Aside = ({ position }) => {
    const bottom = position === "bottom"
    return (
      <aside className={cx("aside", { bottom })}>
        <div className={styles.subscribeContainer}>
          <FormBlockSmall {...requiredForm} centered={bottom} gray={backgroundType === "white"} btn={true}/>
        </div>
      </aside>
    )
  }
  // imagePosition: true => image in right, false => image in left
  const textEffect = 'fadeInLeft'
  const imageEffect = 'fadeInRight'
  const showContent = label || title || description || image || video
  return (
    <section
      id={sectionId}
      className={cx("container", {
        white: backgroundType === "white",
        lightMint: backgroundType === "light mint",
        navy: backgroundType === "navy",
        teal: backgroundType === "teal"
      })}
    >
      <PaddedContent>
        {sectionHeading && (
          <SectionHeader
            centered={true}
            {...sectionHeading}
            containerClass={cx('header')}
            maxWidthTitle="815px"
          />
        )}
        {showContent && <div
          className={cx("flex", {
            hasSectionHeading: sectionHeading,
          })}
        >

          <Reveal effect={imageEffect} duration={700}>
            <div className={cx('text', !(image || video) && 'withoutImage')}>
              {(label || title || description) && (
                <div>
                  {title && <h2 className={cx("title", "h3")}>{title}</h2>}
                  {label && <div className={styles.label}>{label}</div>}
                  {description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description.childMarkdownRemark.html,
                      }}
                      className={styles.description}
                    />
                  )}
                </div>
              )}
              {checklist && (
                <div className={styles.listWrapper}>
                  <TwoColumnList red oneColumn list={checklist} />
                </div>
              )}

              {(image || video) && (
                <Reveal effect={textEffect} duration={700}>
                  <div className={styles.imageWrapper}>
                    {image?.file?.url && <img src={image.file.url} alt={image.title} />}
                    {video && (
                      <Video className={`${styles.video} no-alt`} {...video} />
                    )}
                  </div>
                </Reveal>
              )}
              {link && (
                <ContentfulLink {...link} className={styles.cta}>
                  {link.linkText}
                </ContentfulLink>
              )}
            </div>
          </Reveal>
          {requiredForm && <div className={styles.formContainer}><Aside /></div>}
        </div>}
      </PaddedContent>
    </section>
  )
}

export default FormWidthImageAndTextSection

export const formWidthImageAndTextSection = graphql`
  fragment FormWidthImageAndTextSection on ContentfulFormWithImageAndTextSection {
    sectionHeading {
      ...SectionHeading
    }
    title
    label
    description {
      childMarkdownRemark {
        html
      }
    }
    link {
      ...Link
    }
    checklist {
      ... on Node {
        ... on ContentfulText {
          ...Text
        }
        ... on ContentfulLink {
          ...Link
        }
        ... on ContentfulTextBlock {
          title
          text
          titleColor
          icon {
            file {
              url
            }
         }
        }
      }
    }
    backgroundType
    image {
      file {
        url
      }
      title
    }
    requiredForm {
      ...Form
    }
  }
`

import React from "react"
import { graphql } from 'gatsby'
// import EncompassSectionBgSvg from "images/encompass-section-bg.svg"
import BarGroup from "images/bar-group.svg"
import { useBreakpoint } from "hooks/use-breakpoint"
import Reveal from 'react-reveal/Reveal'
import PaddedContent from "components/paddedContent"

import styleVars from "styles/variables.scss"
import styles from "styles/sections/list-image-and-text-section.module.scss"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const ListImageAndTextSection = ({
  header,
  imageAndTextSection = []
}) => {
  const [isTablet, loaded] = useBreakpoint(styleVars.breakpointTablet, -1)

  return (
    <section
      className={cx("container", 'relative')}
    > 
      <div className={cx('wrapSectionBg')}>
        <PaddedContent>
          <div className={cx('relative')}>
            <div className={cx('sectionBg')}>
              <BarGroup />
            </div>
          </div>
        </PaddedContent>
      </div>
      <Reveal effect="fadeInUp" duration={1000}>
        <PaddedContent>
          <div className={cx('relative')}>
            <div className={cx('wrapHeader')}>
              <h2 className={cx('title')}>
                {header}
                <span className={cx('line')}></span>
              </h2>
            </div>
          </div>
        </PaddedContent>
      </Reveal>
      <PaddedContent>
      {imageAndTextSection.map((imageAndTextItem, index) => (
        <div
          key={`image-and-text-${index}`}
          className={cx("flex", 'imageAndTextItem')}
        >
          <div className={cx('imageWrapper', {
            'bgMin100': imageAndTextItem.backgroundColor === 'Mint-100',
            'bgNavy': imageAndTextItem.backgroundColor === 'Navy',
            'bgTeal': imageAndTextItem.backgroundColor === 'Teal',
          })}>
            <img src={loaded && imageAndTextItem[isTablet ? 'imageMobile' : 'imageDesktop'].file?.url} alt={imageAndTextItem.title} />
          </div>
          <div className={cx('text', loaded && !imageAndTextItem[isTablet ? 'imageMobile' : 'imageDesktop'].file?.url && 'withoutImage', {
              'subMin100': imageAndTextItem.backgroundColor === 'Mint-100',
              'subNavy': imageAndTextItem.backgroundColor === 'Navy',
              'subTeal': imageAndTextItem.backgroundColor === 'Teal',
            })}>
            <div>
              {imageAndTextItem.title && <h3 className={styles.title}>{imageAndTextItem.title}</h3>}
              {imageAndTextItem.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: imageAndTextItem.description?.childMarkdownRemark?.html,
                  }}
                  className={styles.description}
                />
              )}
            </div>
          </div>
        </div>
      ))}
      </PaddedContent>
    </section>
  )
}

export default ListImageAndTextSection

export const listImageAndTextSection = graphql`
  fragment ListImageAndTextSection on ContentfulListImageAndTextSection {
    header
    imageAndTextSection {
      backgroundColor
      description {
        childMarkdownRemark {
          html
        }
      }
      imageDesktop {
        file {
          url
        }
      }
      imageMobile {
        file {
          url
        }
      }
      title
    }
  }
`

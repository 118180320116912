import React from "react"
import { graphql } from 'gatsby'

import PaddedContent from "components/paddedContent"

import styles from "styles/sections/text-section.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)
const TextSection = ({ markdown, textAlignment }) => {
  return (
    <section>
      <PaddedContent>
        {/* EC-12455: Text alignment field does not do anything for "Text Section" content model */}
        <div className={cx(styles.container, {
              left: textAlignment === 'Left',
              right: textAlignment === 'Right'
            })}>
          {markdown && (
            <div
              dangerouslySetInnerHTML={{
                __html: markdown.childMarkdownRemark.html,
              }}
            />
          )}
        </div>
      </PaddedContent>
    </section>
  )
}

export default TextSection

export const textSection = graphql`
  fragment TextSection on ContentfulTextSection {
    id
    markdown {
      childMarkdownRemark {
        html
      }
    }
    textAlignment
  }
`
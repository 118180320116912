
import React from 'react'
import Background from "images/hero-background.svg"
import BackgroundMobile from "images/hero-background-mobile.svg"
import BackgroundMint from "images/hero-background-mint.svg"
import BackgroundMintMobile from "images/hero-background-mint-mobile.svg"
import BackgroundLightMint from "images/hero-background-light-mint.svg"
import BackgroundLightMintMobile from "images/hero-background-light-mint-mobile.svg"
import BackgroundTeal from "images/hero-background-teal.svg"
import BackgroundTealMobile from "images/hero-background-teal-mobile.svg"

const BackgroundHero = ({ backgroundType, isTablet }) => {
  if (backgroundType === "mint") {
    return isTablet ? <BackgroundMintMobile /> : <BackgroundMint />
  }
  if (backgroundType === "light mint") {
    return isTablet ? <BackgroundLightMintMobile /> : <BackgroundLightMint />
  }
  if (backgroundType === "teal") {
    return isTablet ? <BackgroundTealMobile/> : <BackgroundTeal />
  }
  return isTablet ? <BackgroundMobile /> : <Background />
}

export default BackgroundHero